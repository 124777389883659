import { Component, OnInit, Injector, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, EventSettingsModel } from '@syncfusion/ej2-angular-schedule';
import * as moment from 'moment';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/public_api';
import { CommonHelper } from '@shared/helpers/CommonHelper';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CalendarServiceProxy, DataInfo, EventInfo, InfoTeamCalendar, TeamCalendarDto, UserServiceProxy } from '@shared/service-proxies/service-proxies';


@Component({
    selector: 'app-team-calendar-base',
    templateUrl: './team-calendar-base.component.html',
    styleUrls: ['./team-calendar-base.component.css'],
    providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class TeamCalendarBaseComponent extends AppComponentBase implements OnInit {

    numberOfWeeks = [0, 1, 2, 3];
    startDate = '';
    endDate = '';

    teamCalendarData: TeamCalendarDto[] = [];
    isLoadingDone = false;

    currentPopoverContext: EventInfo;
    backupPersonTitle = this.l('BackupPerson');
    backupPerson: string;
    haveBackupPerson: boolean;
    allowanceDaysText: string;
    fullName: string;
    holidaysLeftTitle = this.l('HolidaysLeft');

    commonHelper = CommonHelper;

    isFromDashboard = true;

    @Output() startDateEmitter = new EventEmitter();
    @Output() endDateEmitter = new EventEmitter();

    constructor(
        injector: Injector, private _calendarServiceProxy: CalendarServiceProxy, private _userServiceProxy: UserServiceProxy,
        private route: ActivatedRoute) {
        super(injector);

        const fromDashboard = this.route.snapshot.data['fromDashboard'];
        // A validação NÃO pode ser feita da seguinte forma 'if (fromDashboard)'
        if (fromDashboard != undefined) this.isFromDashboard = fromDashboard;

        this.getWeekNamesAndDays([], '', '', []);
    }

    ngOnInit(): void {
        this.getTeamCalendarEvents();
    }

    getCurrentPopoverContextData(itc: EventInfo) {
        itc.event = this.l(itc.event);
        this.currentPopoverContext = itc;
    }

    getBackupPerson(backupPerson: string, haveBackupPerson: boolean, allowanceDays: number, fullName: string) {
        this.haveBackupPerson = haveBackupPerson;
        this.backupPerson = haveBackupPerson ? backupPerson : this.l('NoBackupPersonDefined');
        if (allowanceDays) this.allowanceDaysText = this.l('AboutDays').replace('[ALLOWANCEDAYS]', allowanceDays.toString());
        this.fullName = fullName;
    }

    getNextWeek() {
        const currentLastWeek = this.numberOfWeeks[this.numberOfWeeks.length - 1];
        if (isNaN(currentLastWeek)) { return; }

        this.numberOfWeeks.shift();
        this.numberOfWeeks.push(currentLastWeek + 1);
        this.teamCalendarData.forEach(tcd => tcd.datasTeamCalendar = this.getWeekNamesAndDays(tcd.infosTeamCalendar, tcd.name, tcd.surname, tcd.daysOff));
    }

    getPreviousWeek() {
        const currentFirstWeek = this.numberOfWeeks[0];
        if (isNaN(currentFirstWeek)) { return; }

        this.numberOfWeeks.pop();
        this.numberOfWeeks.unshift(currentFirstWeek - 1);
        this.teamCalendarData.forEach(tcd => tcd.datasTeamCalendar = this.getWeekNamesAndDays(tcd.infosTeamCalendar, tcd.name, tcd.surname, tcd.daysOff));
    }

    getWeekNamesAndDays(dataEvents: Array<InfoTeamCalendar>, name: string, surname: string, daysOff: Array<number>) {
        const currentDate = moment();

        const weekStart = currentDate.clone().startOf('isoWeek');

        let weekNamesAndDays = new Array<DataInfo>();

        for (let j = 0; j < this.numberOfWeeks.length; j++) {
            for (let i = 0; i <= 6; i++) {
                const myMoment = moment(weekStart).add(this.numberOfWeeks[j], 'week').add(i, 'days');
                const event = this.haveEvent(dataEvents, myMoment);
                weekNamesAndDays.push({
                    dayName: myMoment.format('dddd')[0],
                    dayNumber: myMoment.format('D'),
                    isWeekend: daysOff.includes(myMoment.isoWeekday()),
                    haveEvent: (event.event != ''),
                    event: new EventInfo(name, surname, event.event, event.color, event.startingDT, event.endingDT, event.dataDifference)
                });
            }
        }

        this.startDate = moment(weekStart).add(this.numberOfWeeks[0], 'week').add(0, 'days').format('D MMM');
        this.endDate = moment(weekStart).add(this.numberOfWeeks[this.numberOfWeeks.length - 1], 'week').add(6, 'days').format('D MMM ');

        this.startDateEmitter.emit(this.startDate);
        this.endDateEmitter.emit(this.endDate);

        this.isLoadingDone = true;

        return weekNamesAndDays;
    }

    private haveEvent(dataEvents: Array<InfoTeamCalendar>, myMoment: moment.Moment): any {
        let event = '';
        let color = 'transparent';
        let startingDT = null;
        let endingDT = null;
        let dataDifference = null;
        dataEvents.forEach(dataEvent => {
            if (myMoment.isSame(dataEvent.startingDT, 'day') || myMoment.isBetween(dataEvent.startingDT, dataEvent.endingDT, 'day') || myMoment.isSame(dataEvent.endingDT, 'day')) {
                event = dataEvent.eventType;
                color = dataEvent.color;
                startingDT = dataEvent.startingDT;
                endingDT = dataEvent.endingDT;
                // dataDifference = moment(dataEvent.endingDT).diff(dataEvent.startingDT);
            }
        });

        return { event: event, color: color, startingDT: startingDT, endingDT: endingDT, dataDifference: dataDifference };
    }

    getDayClass(dataInfo: DataInfo) {
        let className = '';

        if (dataInfo.isWeekend) {
            className = 'isWeekend';
        }

        if (dataInfo.haveEvent) {
            className += (className === '' ? 'haveEvent' : ' haveEvent');
        }

        return className;
    }

    async getTeamCalendarEvents(event?: LazyLoadEvent) {
       this._calendarServiceProxy.getLeaveDetailsForTeamCalendar().subscribe(result => {
           if (result && result.length > 0) {
                result.forEach(res => {
                    res.datasTeamCalendar = this.getWeekNamesAndDays(res.infosTeamCalendar, res.name, res.surname, res.daysOff);
                    this.teamCalendarData.push(res);
                });
           } else {
            this._calendarServiceProxy.getAllAdminUserNamesOfCurrentTenant().subscribe(adminUsers => {
                    abp.message.info(this.l('NotAssignedToDepartment') + ' ' + adminUsers.join(", "));
                });
           }
           this.isLoadingDone = true;
        });
    }
}
