import { Component, OnInit, Injector, ViewEncapsulation, Input, ViewChildren, ElementRef } from '@angular/core';
import { MyCalendarDto, CalendarServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import {
    ChangeDetectionStrategy,
    ViewChild,
    TemplateRef,
} from '@angular/core';
import {
    startOfDay,
    endOfDay,
    subDays,
    addDays,
    endOfMonth,
    isSameDay,
    isSameMonth,
    addHours,
} from 'date-fns';
import { Subject } from 'rxjs';

import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView,
} from 'angular-calendar';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CalendarPreviousViewDirective } from 'angular-calendar/modules/common/calendar-previous-view.directive';
import { CalendarNextViewDirective } from 'angular-calendar/modules/common/calendar-next-view.directive';


const colors: any = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
    green: {
        primary: '#e3bc08',
        secondary: '#FDF1BA'
    }
};

@Component({
    selector: 'app-month-calendar-base',
    templateUrl: './month-calendar-base.component.html',
    styleUrls: ['./month-calendar-base.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class MonthCalendarBaseComponent extends AppComponentBase implements OnInit {

    @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
    myCalendarDto = new MyCalendarDto();
    myCalendarData: MyCalendarDto[] = [];
    currentCalendarYear: number;
    view: CalendarView = CalendarView.Month;
    submit: boolean = false;
    CalendarView = CalendarView;
    events: CalendarEvent[] = [];
    yearEvents: CalendarEvent[] = [];
    viewDate: Date = new Date();
    viewDateYear: Date = new Date();
    tabIndex: number;
    modalData: {
        action: string;
        event: CalendarEvent;

    };
    refresh: Subject<any> = new Subject();
    activeDayIsOpen: boolean = false;

    formatDate = 'yyyy'

    daysOff: Array<number> = [];

    constructor(
        injector: Injector, private _calendarServiceProxy: CalendarServiceProxy) {
        super(injector);
    }

    ngOnInit(): void {
    }

    getCalendarEvents(year: number) {
        this.currentCalendarYear = year;
        this.viewDate = new Date();
        var first_day = moment(new Date(year, 0, 1));
        var last_day = moment(new Date(year, 12, 0));
        this._calendarServiceProxy.getLeaveDetailsForCalendar(first_day, last_day).subscribe((result: any) => {
            this.daysOff = result.daysOff;
            this.myCalendarData = result.myCalendarDto;
            this.configureYearEvents(result.myCalendarDto);
            this.getUserCalendarData();
        });
    }

    private configureYearEvents(data: any) {
        let saveData = new Array<CalendarEvent>();
        let thisDate = new Date();
        data.forEach(p => {
            let diffDays = p.endingDT.diff(p.startingDT, 'days');
            for (let i = 0; i <= diffDays; i++) {
                thisDate = p.startingDT.toDate();
                let myMoment = moment(thisDate).add(i, 'days').toDate();
                let calendarEvent = <CalendarEvent>{start: myMoment, title: p.leaveTypeName + ', ' + p.leaveStatus, end: myMoment, color: {primary: p.color, secondary: p.color} };
                saveData.push(calendarEvent);
            }
        });

        this.yearEvents = saveData;
    }

    nextYear() {
        // let element = document.getElementById('nextBtn') as HTMLElement;
        // element.click();
        var nextYear = this.currentCalendarYear + 1;
        this.getCalendarEvents(nextYear);
    }

    previousYear() {
        // let element = document.getElementById('previousBtn') as HTMLElement;
        // element.click();
        var nextYear = this.currentCalendarYear - 1;
        this.getCalendarEvents(nextYear);
    }

    actions: CalendarEventAction[] = [
        {
            label: '<i class="fa fa-fw fa-pencil"></i>',
            a11yLabel: 'Edit',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.handleEvent('Edited', event);
            },
        },
        {
            label: '<i class="fa fa-fw fa-times"></i>',
            a11yLabel: 'Delete',
            onClick: ({ event }: { event: CalendarEvent }): void => {
                this.events = this.events.filter((iEvent) => iEvent !== event);
                this.handleEvent('Deleted', event);
            },
        },
    ];

    InitializeMonthTab() {
        this.formatDate = 'MMMM yyyy';
        this.viewDate = new Date(2020, 0);
        this.getCalendarEvents(this.viewDate.getFullYear());
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
        if (isSameMonth(date, this.viewDate)) {
            if (
                (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    }

    eventTimesChanged({
        event,
        newStart,
        newEnd,
    }: CalendarEventTimesChangedEvent): void {
        this.events = this.events.map((iEvent) => {
            if (iEvent === event) {
                return {
                    ...event,
                    start: newStart,
                    end: newEnd,
                };
            }
            return iEvent;
        });
        this.handleEvent('Dropped or resized', event);
    }

    handleEvent(action: string, event: CalendarEvent): void {
        this.modalData = { event, action };
        //this.modal.open(this.modalContent, { size: 'lg' });
    }

    addEvent(): void {
        this.events = [
            ...this.events,
            {
                title: 'New event',
                start: startOfDay(new Date()),
                end: endOfDay(new Date()),
                color: colors.red,
                draggable: true,
                resizable: {
                    beforeStart: true,
                    afterEnd: true,
                },
            },
        ];
    }

    deleteEvent(eventToDelete: CalendarEvent) {
        this.events = this.events.filter((event) => event !== eventToDelete);
    }

    setView(view: CalendarView) {
        this.view = view;
    }

    closeOpenMonthViewDay() {
        this.activeDayIsOpen = false;
    }

    getUserCalendarData() {
        this.submit = true;
        this.events = this.myCalendarData.map(p => { return <CalendarEvent>{ start: p.startingDT.toDate(), title: p.leaveTypeName + ", " + p.leaveStatus, end: p.endingDT.toDate(), color: {primary: p.color, secondary: p.color} }});
    }

    nothingToshowText: any = this.l('ThereAreNoEventsScheduledThatDay');

    themecolor: any = '#0a5ab3';

    eventClicked(event) {
        console.log(event);
    }

    actionClicked(event) {
        console.log('action', event.action)
        console.log('event', event.event)
    }
}
