import { AfterViewInit } from '@angular/core';
import { Component, OnInit, Injector, ViewEncapsulation, ViewChild } from '@angular/core';
import { MonthCalendarBaseComponent } from '@app/shared/common/month-calendar-base/month-calendar-base.component';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { WidgetComponentBase } from '../widget-component-base';

@Component({
    selector: 'app-widget-month-calendar',
    templateUrl: './widget-month-calendar.component.html',
    styleUrls: ['./widget-month-calendar.component.less'],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})

export class WidgetMonthCalendarComponent extends WidgetComponentBase implements OnInit, AfterViewInit {

    @ViewChild('mc') monthCalendar: MonthCalendarBaseComponent;

    viewDate: Date = new Date();

    formatDate = 'MMMM yyyy'

    constructor(
        injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.monthCalendar.InitializeMonthTab();
    }
}
