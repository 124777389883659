import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxBootstrapLocaleMappingService } from 'assets/ngx-bootstrap/ngx-bootstrap-locale-mapping.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';

export class NgxBootstrapDatePickerConfigService {

    static getDaterangepickerConfig(): BsDaterangepickerConfig {
        return Object.assign(new BsDaterangepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getDatepickerConfig(): BsDatepickerConfig {
        return Object.assign(new BsDatepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getTheme(): string {
        return ThemeHelper.getTheme();
    }

    static getDatepickerLocale(): BsLocaleService {
        let language = abp.localization.currentLanguage.name;
        let localeService = new BsLocaleService();
        if (language === 'en') language = 'en-gb';
        if (language === 'pt-PT') language = 'pt-BR';
        localeService.use(language);
        return localeService;
    }

    static registerNgxBootstrapDatePickerLocales(): Promise<boolean> {
        let language = abp.localization.currentLanguage.name;
        // if (abp.localization.currentLanguage.name === 'en') {
        //     return Promise.resolve(true);
        // }

        if (language === 'en') language = 'en-gb';
        if (language === 'pt-PT') language = 'pt-BR';

        let supportedLocale = new NgxBootstrapLocaleMappingService().map(language).toLowerCase();
        let moduleLocaleName = new NgxBootstrapLocaleMappingService().getModuleName(language);

        return new Promise<boolean>((resolve, reject) => {
            import(`ngx-bootstrap/chronos/esm5/i18n/${supportedLocale}.js`)
                .then(module => {
                    defineLocale(language.toLowerCase(), module[`${moduleLocaleName}Locale`]);
                    resolve(true);
                }, reject);
        });
    }
}
