<div dropdownToggle class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="30px,0px" aria-expanded="true">
    <span class="kt-header__topbar-icon kt-header__topbar-icon--primary">
        <div *ngIf="unreadNotificationCount">
            <i class="flaticon-alert-2 unread-notification"></i>
            <span class="kt-badge kt-badge--primary unread-notification-count">
                {{unreadNotificationCount}}
            </span>
        </div>
        <div *ngIf="!unreadNotificationCount">
            <i class="flaticon-alarm"></i>
        </div>
    </span>
</div>
<div *dropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
    <div class="kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b" style="min-height: 100px;" [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/default/images/bg/bg-1.jpg)', 'background-size': 'cover'}">
        <h3 class="kt-head__title">
            <span class="btn btn-success btn-sm btn-bold btn-font-md">
                {{unreadNotificationCount}} {{"NewNotifications" | localize}}
            </span>
        </h3>
    </div>
    <div class="kt-grid-nav kt-grid-nav--skin-light">
        <div class="row m-4">
            <div class="text-left col-md-8">
                <a href="javascript:;" (click)="setAllNotificationsAsRead(); $event.preventDefault(); $event.stopPropagation();" *ngIf="unreadNotificationCount">{{"SetAllAsRead" | localize}}</a>
            </div>
            <div class="text-right col-md-4">
                <a (click)="openNotificationSettingsModal()" class="text-right" href="javascript:;">{{"Settings" | localize}}</a>
            </div>
        </div>
        <hr />
        <div class="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll" data-scroll="true" data-height="300" data-mobile-height="200" style="height: 300px; overflow: hidden;">
            <a *ngFor="let notification of notifications" (click)="gotoUrl(notification.url)" [ngClass]="{'user-notification-item-clickable': notification.url,'user-notification-item-unread': notification.isUnread}" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="{{notification.icon}} kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title">
                        {{notification.text}}
                    </div>
                    <div class="kt-notification__item-time">
                        {{notification.time | momentFromNow}}
                        <span *ngIf="notification.isUnread">
                            <a href="javascript:;" *ngIf="notification.state=='UNREAD'" (click)="setNotificationAsRead(notification); $event.preventDefault(); $event.stopPropagation();">{{"SetAsRead" | localize}}</a>
                        </span>
                    </div>
                </div>
            </a>
            <span *ngIf="!notifications.length" class="notification-empty-text p-3">
                {{"ThereIsNoNotification" | localize}}
            </span>
        </div>
        <hr *ngIf="notifications.length" />
        <div *ngIf="notifications.length" class="m-4">
            <div class="text-center"><a routerLink="notifications">{{"SeeAllNotifications" | localize}}</a></div>
        </div>
    </div>
</div>
