import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { UserDelegationsModalComponent } from '@app/shared/layout/user-delegations-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { AddProfileDetailModalComponent } from '@app/shared/layout/profile/add-profile-detail-modal.component';
import { loadCldr, L10n } from "@syncfusion/ej2-base";

declare var require: any;

loadCldr(
  require("cldr-data/main/pt-PT/numbers.json"),
  require("cldr-data/main/pt-PT/ca-gregorian.json"),
  require("cldr-data/main/pt-PT/timeZoneNames.json"),
  require("cldr-data/main/es/numbers.json"),
  require("cldr-data/main/es/ca-gregorian.json"),
  require("cldr-data/main/es/timeZoneNames.json"),
  require("cldr-data/main/fr/numbers.json"),
  require("cldr-data/main/fr/ca-gregorian.json"),
  require("cldr-data/main/fr/timeZoneNames.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require('cldr-data/supplemental/weekdata.json') // To load the culture based first day of week
);

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit {

    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;

    @ViewChild('loginAttemptsModal', { static: true }) loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal') linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('userDelegationsModal', { static: true }) userDelegationsModal: UserDelegationsModalComponent;
    @ViewChild('changePasswordModal', { static: true }) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', { static: true }) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', { static: true }) mySettingsModal: MySettingsModalComponent;
    @ViewChild('notificationSettingsModal', { static: true }) notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('chatBarComponent') chatBarComponent;
    @ViewChild('addProfileDetailModal', { static: true }) addProfileDetailModal: AddProfileDetailModalComponent;

    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
    IsSessionTimeOutEnabled: boolean = this.setting.getBoolean('App.UserManagement.SessionTimeOut.IsEnabled') && this.appSession.userId != null;

    public constructor(
        injector: Injector,
        private _chatSignalrService: ChatSignalrService,
        private _userNotificationHelper: UserNotificationHelper
    ) {
        super(injector);

        this.loadTranslationsPTSyncfusion();
        this.loadTranslationsESSyncfusion();
        this.loadTranslationsFRSyncfusion();
        this.loadTranslationsENSyncfusion();
    }

    ngOnInit(): void {
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        this.registerModalOpenEvents();

        if (this.appSession.application) {
            SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
        }
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on('app.show.loginAttemptsModal', () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on('app.show.linkedAccountsModal', () => {
            this.linkedAccountsModal.show();
        });

        abp.event.on('app.show.userDelegationsModal', () => {
            this.userDelegationsModal.show();
        });

        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on('app.show.addProfileDetailModal', () => {
            this.addProfileDetailModal.show();
        });

        abp.event.on('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger('app.onMySettingsModalSaved');
    }

    loadTranslationsPTSyncfusion(): void {
        L10n.load({
            "pt-pt": {
                "schedule": {
                    "day": this.l('Schedule_Day'),
                    "week": this.l('Schedule_Week'),
                    "workWeek": this.l('Schedule_WorkWeek'),
                    "month": this.l('Schedule_Month'),
                    "year": this.l('Schedule_Year'),
                    "agenda": this.l('Schedule_Agenda'),
                    "weekAgenda": this.l('Schedule_WeekAgenda'),
                    "workWeekAgenda": this.l('Schedule_WorkWeekAgenda'),
                    "monthAgenda": this.l('Schedule_MonthAgenda'),
                    "today": this.l('Schedule_Today'),
                    "noEvents": this.l('Schedule_NoEvents'),
                    "emptyContainer": this.l('Schedule_EmptyContainer'),
                    "allDay": this.l('Schedule_AllDay'),
                    "start": this.l('Schedule_Start'),
                    "end": this.l('Schedule_End'),
                    "more": this.l('Schedule_More'),
                    "close": this.l('Schedule_Close'),
                    "cancel": this.l('Schedule_Cancel'),
                    "noTitle": this.l('Schedule_NoTitle'),
                    "delete": this.l('Schedule_Delete'),
                    "deleteEvent": this.l('Schedule_ThisEvent'),
                    "deleteMultipleEvent": this.l('Schedule_DeleteMultipleEvents'),
                    "selectedItems": this.l('Schedule_ItemsSelected'),
                    "deleteSeries": this.l('Schedule_EntireSeries'),
                    "edit": this.l('Schedule_Edit'),
                    "editSeries": this.l('Schedule_EntireSeries'),
                    "editEvent": this.l('Schedule_ThisEvent'),
                    "createEvent": this.l('Schedule_Create'),
                    "subject": this.l('Schedule_Subject'),
                    "addTitle": this.l('Schedule_AddTitle'),
                    "moreDetails": this.l('Schedule_MoreDetails'),
                    "save": this.l('Schedule_Save'),
                    "editContent": this.l('Schedule_EditContent'),
                    "deleteContent": this.l('Schedule_DeleteContent'),
                    "deleteMultipleContent": this.l('Schedule_DeleteMultipleContent'),
                    "newEvent": this.l('Schedule_NewEvent'),
                    "title": this.l('Schedule_Title'),
                    "location": this.l('Schedule_Location'),
                    "description": this.l('Schedule_Description'),
                    "timezone": this.l('Schedule_Timezone'),
                    "startTimezone": this.l('Schedule_StartTimezone'),
                    "endTimezone": this.l('Schedule_EndTimezone'),
                    "repeat": this.l('Schedule_Repeat'),
                    "saveButton": this.l('Schedule_Save'),
                    "cancelButton": this.l('Schedule_Cancel'),
                    "deleteButton": this.l('Schedule_Delete'),
                    "recurrence": this.l('Schedule_Recurrence'),
                    "wrongPattern": this.l('Schedule_WrongPattern'),
                    "seriesChangeAlert": this.l('Schedule_SeriesChangeAlert'),
                    "createError": this.l('Schedule_CreateError'),
                    "sameDayAlert": this.l('Schedule_SameDayAlert'),
                    "editRecurrence": this.l('Schedule_EditRecurrence'),
                    "repeats": this.l('Schedule_Repeats'),
                    "alert": this.l('Schedule_Alert'),
                    "startEndError": this.l('Schedule_StartEndError'),
                    "invalidDateError": this.l('Schedule_InvalidDateError'),
                    "blockAlert": this.l('Schedule_BlockAlert'),
                    "ok": this.l('Schedule_Ok'),
                    "yes": this.l('Schedule_Yes'),
                    "no": this.l('Schedule_No'),
                    "occurrence": this.l('Schedule_Occurrence'),
                    "series": this.l('Schedule_Series'),
                    "previous": this.l('Schedule_Previous'),
                    "next": this.l('Schedule_Next'),
                    "timelineDay": this.l('Schedule_TimelineDay'),
                    "timelineWeek": this.l('Schedule_TimelineWeek'),
                    "timelineWorkWeek": this.l('Schedule_TimelineWorkWeek'),
                    "timelineMonth": this.l('Schedule_TimelineMonth'),
                    "timelineYear": this.l('Schedule_TimelineYear'),
                    "editFollowingEvent": this.l('Schedule_FollowingEvents'),
                    "deleteTitle": this.l('Schedule_DeleteEvent'),
                    "editTitle": this.l('Schedule_EditEvent'),
                    "beginFrom": this.l('Schedule_BeginFrom'),
                    "endAt": this.l('Schedule_EndAt'),
                    "expandAllDaySection": this.l('Schedule_ExpandAllDaySection'),
                    "collapseAllDaySection": this.l('Schedule_CollapseAllDaySection')
                },
                "recurrenceeditor": {
                    "none": this.l('Recurrenceeditor_None'),
                    "daily": this.l('Recurrenceeditor_Daily'),
                    "weekly": this.l('Recurrenceeditor_Weekly'),
                    "monthly": this.l('Recurrenceeditor_Monthly'),
                    "month": this.l('Recurrenceeditor_Month'),
                    "yearly": this.l('Recurrenceeditor_Yearly'),
                    "never": this.l('Recurrenceeditor_Never'),
                    "until": this.l('Recurrenceeditor_Until'),
                    "count": this.l('Recurrenceeditor_Count'),
                    "first": this.l('Recurrenceeditor_First'),
                    "second": this.l('Recurrenceeditor_Second'),
                    "third": this.l('Recurrenceeditor_Third'),
                    "fourth": this.l('Recurrenceeditor_Fourth'),
                    "last": this.l('Recurrenceeditor_Last'),
                    "repeat": this.l('Recurrenceeditor_Repeat'),
                    "repeatEvery": this.l('Recurrenceeditor_RepeatEvery'),
                    "on": this.l('Recurrenceeditor_RepeatOn'),
                    "end": this.l('Recurrenceeditor_End'),
                    "onDay": this.l('Recurrenceeditor_Day'),
                    "days": this.l('Recurrenceeditor_Days'),
                    "weeks": this.l('Recurrenceeditor_Weeks'),
                    "months": this.l('Recurrenceeditor_Months'),
                    "years": this.l('Recurrenceeditor_Years'),
                    "every": this.l('Recurrenceeditor_Every'),
                    "summaryTimes": this.l('Recurrenceeditor_Times'),
                    "summaryOn": this.l('Recurrenceeditor_On'),
                    "summaryUntil": this.l('Recurrenceeditor_Until'),
                    "summaryRepeat": this.l('Recurrenceeditor_Repeats'),
                    "summaryDay": this.l('Recurrenceeditor_Days'),
                    "summaryWeek": this.l('Recurrenceeditor_Weeks'),
                    "summaryMonth": this.l('Recurrenceeditor_Months'),
                    "summaryYear": this.l('Recurrenceeditor_Years'),
                    "monthWeek": this.l('Recurrenceeditor_MonthWeek'),
                    "monthPosition": this.l('Recurrenceeditor_MonthPosition'),
                    "monthExpander": this.l('Recurrenceeditor_MonthExpander'),
                    "yearExpander": this.l('Recurrenceeditor_YearExpander'),
                    "repeatInterval": this.l('Recurrenceeditor_RepeatInterval')
                },
                "datepicker": {
                    "placeholder": this.l('Datepicker_ChooseDate'),
                    "today":this.l('Datepicker_Today')
                }
            }
        });
    }

    loadTranslationsESSyncfusion(): void {
        L10n.load({
            "es": {
                "schedule": {
                    "day": this.l('Schedule_Day'),
                    "week": this.l('Schedule_Week'),
                    "workWeek": this.l('Schedule_WorkWeek'),
                    "month": this.l('Schedule_Month'),
                    "year": this.l('Schedule_Year'),
                    "agenda": this.l('Schedule_Agenda'),
                    "weekAgenda": this.l('Schedule_WeekAgenda'),
                    "workWeekAgenda": this.l('Schedule_WorkWeekAgenda'),
                    "monthAgenda": this.l('Schedule_MonthAgenda'),
                    "today": this.l('Schedule_Today'),
                    "noEvents": this.l('Schedule_NoEvents'),
                    "emptyContainer": this.l('Schedule_EmptyContainer'),
                    "allDay": this.l('Schedule_AllDay'),
                    "start": this.l('Schedule_Start'),
                    "end": this.l('Schedule_End'),
                    "more": this.l('Schedule_More'),
                    "close": this.l('Schedule_Close'),
                    "cancel": this.l('Schedule_Cancel'),
                    "noTitle": this.l('Schedule_NoTitle'),
                    "delete": this.l('Schedule_Delete'),
                    "deleteEvent": this.l('Schedule_ThisEvent'),
                    "deleteMultipleEvent": this.l('Schedule_DeleteMultipleEvents'),
                    "selectedItems": this.l('Schedule_ItemsSelected'),
                    "deleteSeries": this.l('Schedule_EntireSeries'),
                    "edit": this.l('Schedule_Edit'),
                    "editSeries": this.l('Schedule_EntireSeries'),
                    "editEvent": this.l('Schedule_ThisEvent'),
                    "createEvent": this.l('Schedule_Create'),
                    "subject": this.l('Schedule_Subject'),
                    "addTitle": this.l('Schedule_AddTitle'),
                    "moreDetails": this.l('Schedule_MoreDetails'),
                    "save": this.l('Schedule_Save'),
                    "editContent": this.l('Schedule_EditContent'),
                    "deleteContent": this.l('Schedule_DeleteContent'),
                    "deleteMultipleContent": this.l('Schedule_DeleteMultipleContent'),
                    "newEvent": this.l('Schedule_NewEvent'),
                    "title": this.l('Schedule_Title'),
                    "location": this.l('Schedule_Location'),
                    "description": this.l('Schedule_Description'),
                    "timezone": this.l('Schedule_Timezone'),
                    "startTimezone": this.l('Schedule_StartTimezone'),
                    "endTimezone": this.l('Schedule_EndTimezone'),
                    "repeat": this.l('Schedule_Repeat'),
                    "saveButton": this.l('Schedule_Save'),
                    "cancelButton": this.l('Schedule_Cancel'),
                    "deleteButton": this.l('Schedule_Delete'),
                    "recurrence": this.l('Schedule_Recurrence'),
                    "wrongPattern": this.l('Schedule_WrongPattern'),
                    "seriesChangeAlert": this.l('Schedule_SeriesChangeAlert'),
                    "createError": this.l('Schedule_CreateError'),
                    "sameDayAlert": this.l('Schedule_SameDayAlert'),
                    "editRecurrence": this.l('Schedule_EditRecurrence'),
                    "repeats": this.l('Schedule_Repeats'),
                    "alert": this.l('Schedule_Alert'),
                    "startEndError": this.l('Schedule_StartEndError'),
                    "invalidDateError": this.l('Schedule_InvalidDateError'),
                    "blockAlert": this.l('Schedule_BlockAlert'),
                    "ok": this.l('Schedule_Ok'),
                    "yes": this.l('Schedule_Yes'),
                    "no": this.l('Schedule_No'),
                    "occurrence": this.l('Schedule_Occurrence'),
                    "series": this.l('Schedule_Series'),
                    "previous": this.l('Schedule_Previous'),
                    "next": this.l('Schedule_Next'),
                    "timelineDay": this.l('Schedule_TimelineDay'),
                    "timelineWeek": this.l('Schedule_TimelineWeek'),
                    "timelineWorkWeek": this.l('Schedule_TimelineWorkWeek'),
                    "timelineMonth": this.l('Schedule_TimelineMonth'),
                    "timelineYear": this.l('Schedule_TimelineYear'),
                    "editFollowingEvent": this.l('Schedule_FollowingEvents'),
                    "deleteTitle": this.l('Schedule_DeleteEvent'),
                    "editTitle": this.l('Schedule_EditEvent'),
                    "beginFrom": this.l('Schedule_BeginFrom'),
                    "endAt": this.l('Schedule_EndAt'),
                    "expandAllDaySection": this.l('Schedule_ExpandAllDaySection'),
                    "collapseAllDaySection": this.l('Schedule_CollapseAllDaySection')
                },
                "recurrenceeditor": {
                    "none": this.l('Recurrenceeditor_None'),
                    "daily": this.l('Recurrenceeditor_Daily'),
                    "weekly": this.l('Recurrenceeditor_Weekly'),
                    "monthly": this.l('Recurrenceeditor_Monthly'),
                    "month": this.l('Recurrenceeditor_Month'),
                    "yearly": this.l('Recurrenceeditor_Yearly'),
                    "never": this.l('Recurrenceeditor_Never'),
                    "until": this.l('Recurrenceeditor_Until'),
                    "count": this.l('Recurrenceeditor_Count'),
                    "first": this.l('Recurrenceeditor_First'),
                    "second": this.l('Recurrenceeditor_Second'),
                    "third": this.l('Recurrenceeditor_Third'),
                    "fourth": this.l('Recurrenceeditor_Fourth'),
                    "last": this.l('Recurrenceeditor_Last'),
                    "repeat": this.l('Recurrenceeditor_Repeat'),
                    "repeatEvery": this.l('Recurrenceeditor_RepeatEvery'),
                    "on": this.l('Recurrenceeditor_RepeatOn'),
                    "end": this.l('Recurrenceeditor_End'),
                    "onDay": this.l('Recurrenceeditor_Day'),
                    "days": this.l('Recurrenceeditor_Days'),
                    "weeks": this.l('Recurrenceeditor_Weeks'),
                    "months": this.l('Recurrenceeditor_Months'),
                    "years": this.l('Recurrenceeditor_Years'),
                    "every": this.l('Recurrenceeditor_Every'),
                    "summaryTimes": this.l('Recurrenceeditor_Times'),
                    "summaryOn": this.l('Recurrenceeditor_On'),
                    "summaryUntil": this.l('Recurrenceeditor_Until'),
                    "summaryRepeat": this.l('Recurrenceeditor_Repeats'),
                    "summaryDay": this.l('Recurrenceeditor_Days'),
                    "summaryWeek": this.l('Recurrenceeditor_Weeks'),
                    "summaryMonth": this.l('Recurrenceeditor_Months'),
                    "summaryYear": this.l('Recurrenceeditor_Years'),
                    "monthWeek": this.l('Recurrenceeditor_MonthWeek'),
                    "monthPosition": this.l('Recurrenceeditor_MonthPosition'),
                    "monthExpander": this.l('Recurrenceeditor_MonthExpander'),
                    "yearExpander": this.l('Recurrenceeditor_YearExpander'),
                    "repeatInterval": this.l('Recurrenceeditor_RepeatInterval')
                },
                "datepicker": {
                    "placeholder": this.l('Datepicker_ChooseDate'),
                    "today":this.l('Datepicker_Today')
                }
            }
        });
    }

    loadTranslationsFRSyncfusion(): void {
        L10n.load({
            "fr": {
                "schedule": {
                    "day": this.l('Schedule_Day'),
                    "week": this.l('Schedule_Week'),
                    "workWeek": this.l('Schedule_WorkWeek'),
                    "month": this.l('Schedule_Month'),
                    "year": this.l('Schedule_Year'),
                    "agenda": this.l('Schedule_Agenda'),
                    "weekAgenda": this.l('Schedule_WeekAgenda'),
                    "workWeekAgenda": this.l('Schedule_WorkWeekAgenda'),
                    "monthAgenda": this.l('Schedule_MonthAgenda'),
                    "today": this.l('Schedule_Today'),
                    "noEvents": this.l('Schedule_NoEvents'),
                    "emptyContainer": this.l('Schedule_EmptyContainer'),
                    "allDay": this.l('Schedule_AllDay'),
                    "start": this.l('Schedule_Start'),
                    "end": this.l('Schedule_End'),
                    "more": this.l('Schedule_More'),
                    "close": this.l('Schedule_Close'),
                    "cancel": this.l('Schedule_Cancel'),
                    "noTitle": this.l('Schedule_NoTitle'),
                    "delete": this.l('Schedule_Delete'),
                    "deleteEvent": this.l('Schedule_ThisEvent'),
                    "deleteMultipleEvent": this.l('Schedule_DeleteMultipleEvents'),
                    "selectedItems": this.l('Schedule_ItemsSelected'),
                    "deleteSeries": this.l('Schedule_EntireSeries'),
                    "edit": this.l('Schedule_Edit'),
                    "editSeries": this.l('Schedule_EntireSeries'),
                    "editEvent": this.l('Schedule_ThisEvent'),
                    "createEvent": this.l('Schedule_Create'),
                    "subject": this.l('Schedule_Subject'),
                    "addTitle": this.l('Schedule_AddTitle'),
                    "moreDetails": this.l('Schedule_MoreDetails'),
                    "save": this.l('Schedule_Save'),
                    "editContent": this.l('Schedule_EditContent'),
                    "deleteContent": this.l('Schedule_DeleteContent'),
                    "deleteMultipleContent": this.l('Schedule_DeleteMultipleContent'),
                    "newEvent": this.l('Schedule_NewEvent'),
                    "title": this.l('Schedule_Title'),
                    "location": this.l('Schedule_Location'),
                    "description": this.l('Schedule_Description'),
                    "timezone": this.l('Schedule_Timezone'),
                    "startTimezone": this.l('Schedule_StartTimezone'),
                    "endTimezone": this.l('Schedule_EndTimezone'),
                    "repeat": this.l('Schedule_Repeat'),
                    "saveButton": this.l('Schedule_Save'),
                    "cancelButton": this.l('Schedule_Cancel'),
                    "deleteButton": this.l('Schedule_Delete'),
                    "recurrence": this.l('Schedule_Recurrence'),
                    "wrongPattern": this.l('Schedule_WrongPattern'),
                    "seriesChangeAlert": this.l('Schedule_SeriesChangeAlert'),
                    "createError": this.l('Schedule_CreateError'),
                    "sameDayAlert": this.l('Schedule_SameDayAlert'),
                    "editRecurrence": this.l('Schedule_EditRecurrence'),
                    "repeats": this.l('Schedule_Repeats'),
                    "alert": this.l('Schedule_Alert'),
                    "startEndError": this.l('Schedule_StartEndError'),
                    "invalidDateError": this.l('Schedule_InvalidDateError'),
                    "blockAlert": this.l('Schedule_BlockAlert'),
                    "ok": this.l('Schedule_Ok'),
                    "yes": this.l('Schedule_Yes'),
                    "no": this.l('Schedule_No'),
                    "occurrence": this.l('Schedule_Occurrence'),
                    "series": this.l('Schedule_Series'),
                    "previous": this.l('Schedule_Previous'),
                    "next": this.l('Schedule_Next'),
                    "timelineDay": this.l('Schedule_TimelineDay'),
                    "timelineWeek": this.l('Schedule_TimelineWeek'),
                    "timelineWorkWeek": this.l('Schedule_TimelineWorkWeek'),
                    "timelineMonth": this.l('Schedule_TimelineMonth'),
                    "timelineYear": this.l('Schedule_TimelineYear'),
                    "editFollowingEvent": this.l('Schedule_FollowingEvents'),
                    "deleteTitle": this.l('Schedule_DeleteEvent'),
                    "editTitle": this.l('Schedule_EditEvent'),
                    "beginFrom": this.l('Schedule_BeginFrom'),
                    "endAt": this.l('Schedule_EndAt'),
                    "expandAllDaySection": this.l('Schedule_ExpandAllDaySection'),
                    "collapseAllDaySection": this.l('Schedule_CollapseAllDaySection')
                },
                "recurrenceeditor": {
                    "none": this.l('Recurrenceeditor_None'),
                    "daily": this.l('Recurrenceeditor_Daily'),
                    "weekly": this.l('Recurrenceeditor_Weekly'),
                    "monthly": this.l('Recurrenceeditor_Monthly'),
                    "month": this.l('Recurrenceeditor_Month'),
                    "yearly": this.l('Recurrenceeditor_Yearly'),
                    "never": this.l('Recurrenceeditor_Never'),
                    "until": this.l('Recurrenceeditor_Until'),
                    "count": this.l('Recurrenceeditor_Count'),
                    "first": this.l('Recurrenceeditor_First'),
                    "second": this.l('Recurrenceeditor_Second'),
                    "third": this.l('Recurrenceeditor_Third'),
                    "fourth": this.l('Recurrenceeditor_Fourth'),
                    "last": this.l('Recurrenceeditor_Last'),
                    "repeat": this.l('Recurrenceeditor_Repeat'),
                    "repeatEvery": this.l('Recurrenceeditor_RepeatEvery'),
                    "on": this.l('Recurrenceeditor_RepeatOn'),
                    "end": this.l('Recurrenceeditor_End'),
                    "onDay": this.l('Recurrenceeditor_Day'),
                    "days": this.l('Recurrenceeditor_Days'),
                    "weeks": this.l('Recurrenceeditor_Weeks'),
                    "months": this.l('Recurrenceeditor_Months'),
                    "years": this.l('Recurrenceeditor_Years'),
                    "every": this.l('Recurrenceeditor_Every'),
                    "summaryTimes": this.l('Recurrenceeditor_Times'),
                    "summaryOn": this.l('Recurrenceeditor_On'),
                    "summaryUntil": this.l('Recurrenceeditor_Until'),
                    "summaryRepeat": this.l('Recurrenceeditor_Repeats'),
                    "summaryDay": this.l('Recurrenceeditor_Days'),
                    "summaryWeek": this.l('Recurrenceeditor_Weeks'),
                    "summaryMonth": this.l('Recurrenceeditor_Months'),
                    "summaryYear": this.l('Recurrenceeditor_Years'),
                    "monthWeek": this.l('Recurrenceeditor_MonthWeek'),
                    "monthPosition": this.l('Recurrenceeditor_MonthPosition'),
                    "monthExpander": this.l('Recurrenceeditor_MonthExpander'),
                    "yearExpander": this.l('Recurrenceeditor_YearExpander'),
                    "repeatInterval": this.l('Recurrenceeditor_RepeatInterval')
                },
                "datepicker": {
                    "placeholder": this.l('Datepicker_ChooseDate'),
                    "today":this.l('Datepicker_Today')
                }
            }
        });
    }

    loadTranslationsENSyncfusion(): void {
        L10n.load({
            "en-us": {
                "schedule": {
                    "day": this.l('Schedule_Day'),
                    "week": this.l('Schedule_Week'),
                    "workWeek": this.l('Schedule_WorkWeek'),
                    "month": this.l('Schedule_Month'),
                    "year": this.l('Schedule_Year'),
                    "agenda": this.l('Schedule_Agenda'),
                    "weekAgenda": this.l('Schedule_WeekAgenda'),
                    "workWeekAgenda": this.l('Schedule_WorkWeekAgenda'),
                    "monthAgenda": this.l('Schedule_MonthAgenda'),
                    "today": this.l('Schedule_Today'),
                    "noEvents": this.l('Schedule_NoEvents'),
                    "emptyContainer": this.l('Schedule_EmptyContainer'),
                    "allDay": this.l('Schedule_AllDay'),
                    "start": this.l('Schedule_Start'),
                    "end": this.l('Schedule_End'),
                    "more": this.l('Schedule_More'),
                    "close": this.l('Schedule_Close'),
                    "cancel": this.l('Schedule_Cancel'),
                    "noTitle": this.l('Schedule_NoTitle'),
                    "delete": this.l('Schedule_Delete'),
                    "deleteEvent": this.l('Schedule_ThisEvent'),
                    "deleteMultipleEvent": this.l('Schedule_DeleteMultipleEvents'),
                    "selectedItems": this.l('Schedule_ItemsSelected'),
                    "deleteSeries": this.l('Schedule_EntireSeries'),
                    "edit": this.l('Schedule_Edit'),
                    "editSeries": this.l('Schedule_EntireSeries'),
                    "editEvent": this.l('Schedule_ThisEvent'),
                    "createEvent": this.l('Schedule_Create'),
                    "subject": this.l('Schedule_Subject'),
                    "addTitle": this.l('Schedule_AddTitle'),
                    "moreDetails": this.l('Schedule_MoreDetails'),
                    "save": this.l('Schedule_Save'),
                    "editContent": this.l('Schedule_EditContent'),
                    "deleteContent": this.l('Schedule_DeleteContent'),
                    "deleteMultipleContent": this.l('Schedule_DeleteMultipleContent'),
                    "newEvent": this.l('Schedule_NewEvent'),
                    "title": this.l('Schedule_Title'),
                    "location": this.l('Schedule_Location'),
                    "description": this.l('Schedule_Description'),
                    "timezone": this.l('Schedule_Timezone'),
                    "startTimezone": this.l('Schedule_StartTimezone'),
                    "endTimezone": this.l('Schedule_EndTimezone'),
                    "repeat": this.l('Schedule_Repeat'),
                    "saveButton": this.l('Schedule_Save'),
                    "cancelButton": this.l('Schedule_Cancel'),
                    "deleteButton": this.l('Schedule_Delete'),
                    "recurrence": this.l('Schedule_Recurrence'),
                    "wrongPattern": this.l('Schedule_WrongPattern'),
                    "seriesChangeAlert": this.l('Schedule_SeriesChangeAlert'),
                    "createError": this.l('Schedule_CreateError'),
                    "sameDayAlert": this.l('Schedule_SameDayAlert'),
                    "editRecurrence": this.l('Schedule_EditRecurrence'),
                    "repeats": this.l('Schedule_Repeats'),
                    "alert": this.l('Schedule_Alert'),
                    "startEndError": this.l('Schedule_StartEndError'),
                    "invalidDateError": this.l('Schedule_InvalidDateError'),
                    "blockAlert": this.l('Schedule_BlockAlert'),
                    "ok": this.l('Schedule_Ok'),
                    "yes": this.l('Schedule_Yes'),
                    "no": this.l('Schedule_No'),
                    "occurrence": this.l('Schedule_Occurrence'),
                    "series": this.l('Schedule_Series'),
                    "previous": this.l('Schedule_Previous'),
                    "next": this.l('Schedule_Next'),
                    "timelineDay": this.l('Schedule_TimelineDay'),
                    "timelineWeek": this.l('Schedule_TimelineWeek'),
                    "timelineWorkWeek": this.l('Schedule_TimelineWorkWeek'),
                    "timelineMonth": this.l('Schedule_TimelineMonth'),
                    "timelineYear": this.l('Schedule_TimelineYear'),
                    "editFollowingEvent": this.l('Schedule_FollowingEvents'),
                    "deleteTitle": this.l('Schedule_DeleteEvent'),
                    "editTitle": this.l('Schedule_EditEvent'),
                    "beginFrom": this.l('Schedule_BeginFrom'),
                    "endAt": this.l('Schedule_EndAt'),
                    "expandAllDaySection": this.l('Schedule_ExpandAllDaySection'),
                    "collapseAllDaySection": this.l('Schedule_CollapseAllDaySection')
                },
                "recurrenceeditor": {
                    "none": this.l('Recurrenceeditor_None'),
                    "daily": this.l('Recurrenceeditor_Daily'),
                    "weekly": this.l('Recurrenceeditor_Weekly'),
                    "monthly": this.l('Recurrenceeditor_Monthly'),
                    "month": this.l('Recurrenceeditor_Month'),
                    "yearly": this.l('Recurrenceeditor_Yearly'),
                    "never": this.l('Recurrenceeditor_Never'),
                    "until": this.l('Recurrenceeditor_Until'),
                    "count": this.l('Recurrenceeditor_Count'),
                    "first": this.l('Recurrenceeditor_First'),
                    "second": this.l('Recurrenceeditor_Second'),
                    "third": this.l('Recurrenceeditor_Third'),
                    "fourth": this.l('Recurrenceeditor_Fourth'),
                    "last": this.l('Recurrenceeditor_Last'),
                    "repeat": this.l('Recurrenceeditor_Repeat'),
                    "repeatEvery": this.l('Recurrenceeditor_RepeatEvery'),
                    "on": this.l('Recurrenceeditor_RepeatOn'),
                    "end": this.l('Recurrenceeditor_End'),
                    "onDay": this.l('Recurrenceeditor_Day'),
                    "days": this.l('Recurrenceeditor_Days'),
                    "weeks": this.l('Recurrenceeditor_Weeks'),
                    "months": this.l('Recurrenceeditor_Months'),
                    "years": this.l('Recurrenceeditor_Years'),
                    "every": this.l('Recurrenceeditor_Every'),
                    "summaryTimes": this.l('Recurrenceeditor_Times'),
                    "summaryOn": this.l('Recurrenceeditor_On'),
                    "summaryUntil": this.l('Recurrenceeditor_Until'),
                    "summaryRepeat": this.l('Recurrenceeditor_Repeats'),
                    "summaryDay": this.l('Recurrenceeditor_Days'),
                    "summaryWeek": this.l('Recurrenceeditor_Weeks'),
                    "summaryMonth": this.l('Recurrenceeditor_Months'),
                    "summaryYear": this.l('Recurrenceeditor_Years'),
                    "monthWeek": this.l('Recurrenceeditor_MonthWeek'),
                    "monthPosition": this.l('Recurrenceeditor_MonthPosition'),
                    "monthExpander": this.l('Recurrenceeditor_MonthExpander'),
                    "yearExpander": this.l('Recurrenceeditor_YearExpander'),
                    "repeatInterval": this.l('Recurrenceeditor_RepeatInterval')
                },
                "datepicker": {
                    "placeholder": this.l('Datepicker_ChooseDate'),
                    "today":this.l('Datepicker_Today')
                }
            }
        });
    }
}

export class Schedule {
    constructor(day: string, week: string, workWeek: string, month: string, year: string, agenda: string, weekAgenda: string,
                workWeekAgenda: string, monthAgenda: string, today: string, noEvents: string, emptyContainer: string, 
                allDay: string, start: string, end: string, more: string, close: string, cancel: string, noTitle: string,
                delet: string, deleteEvent: string, deleteMultipleEvent: string, selectedItems: string, deleteSeries: string,
                edit: string, editSeries: string, editEvent: string, createEvent: string, subject: string, addTitle: string,
                moreDetails: string, save: string, editContent: string, deleteContent: string, deleteMultipleContent: string, 
                newEvent: string, title: string, location: string, description: string, timezone: string, startTimezone: string,
                endTimezone: string, repeat: string, saveButton: string, cancelButton: string, deleteButton: string, 
                recurrence: string, wrongPattern: string, seriesChangeAlert: string, createError: string, sameDayAlert: string,
                editRecurrence: string, repeats: string, alert: string, startEndError: string, invalidDateError: string,
                blockAlert: string, ok: string, yes: string, no: string, occurrence: string, series: string, previous: string,
                next: string, timelineDay: string, timelineWeek: string, timelineWorkWeek: string, timelineMonth: string,
                timelineYear: string, editFollowingEvent: string, deleteTitle: string, editTitle: string, beginFrom: string,
                endAt: string, expandAllDaySection: string, collapseAllDaySection: string,) {

        this.day = day;
        this.week = week;
        this.workWeek = workWeek;
        this.month = month;
        this.year = year;
        this.agenda = agenda;
        this.weekAgenda = weekAgenda;
        this.workWeekAgenda = workWeekAgenda;
        this.monthAgenda = monthAgenda;
        this.today = today;
        this.noEvents = noEvents;
        this.emptyContainer = emptyContainer;
        this.allDay = allDay;
        this.start = start;
        this.end = end;
        this.more = more;
        this.close = close;
        this.cancel = cancel;
        this.noTitle = noTitle;
        this.delete = delet;
        this.deleteEvent = deleteEvent;
        this.deleteMultipleEvent = deleteMultipleEvent;
        this.selectedItems = selectedItems;
        this.deleteSeries = deleteSeries;
        this.edit = edit;
        this.editSeries = editSeries;
        this.editEvent = editEvent;
        this.createEvent = createEvent;
        this.subject = subject;
        this.addTitle = addTitle;
        this.moreDetails = moreDetails;
        this.save = save;
        this.editContent = editContent;
        this.deleteContent = deleteContent;
        this.deleteMultipleContent = deleteMultipleContent;
        this.newEvent = newEvent;
        this.title = title;
        this.location = location;
        this.description = description;
        this.timezone = timezone;
        this.startTimezone = startTimezone;
        this.endTimezone = endTimezone;
        this.repeat = repeat;
        this.saveButton = saveButton;
        this.cancelButton = cancelButton;
        this.deleteButton = deleteButton;
        this.recurrence = recurrence;
        this.wrongPattern = wrongPattern;
        this.seriesChangeAlert = seriesChangeAlert;
        this.createError = createError;
        this.sameDayAlert = sameDayAlert;
        this.editRecurrence = editRecurrence;
        this.repeats = repeats;
        this.alert = alert;
        this.startEndError = startEndError;
        this.invalidDateError = invalidDateError;
        this.blockAlert = blockAlert;
        this.ok = ok;
        this.yes = yes;
        this.no = no;
        this.occurrence = occurrence;
        this.series = series;
        this.previous = previous;
        this.next = next;
        this.timelineDay = timelineDay;
        this.timelineWeek = timelineWeek;
        this.timelineWorkWeek = timelineWorkWeek;
        this.timelineMonth = timelineMonth;
        this.timelineYear = timelineYear;
        this.editFollowingEvent = editFollowingEvent;
        this.deleteTitle = deleteTitle;
        this.editTitle = editTitle;
        this.beginFrom = beginFrom;
        this.endAt = endAt;
        this.expandAllDaySection = expandAllDaySection;
        this.collapseAllDaySection = collapseAllDaySection;
    }

    day: string;
    week: string;
    workWeek: string;
    month: string;
    year: string;
    agenda: string;
    weekAgenda: string;
    workWeekAgenda: string;
    monthAgenda: string;
    today: string;
    noEvents: string;
    emptyContainer: string;
    allDay: string;
    start: string;
    end: string;
    more: string;
    close: string;
    cancel: string;
    noTitle: string;
    delete: string;
    deleteEvent: string;
    deleteMultipleEvent: string;
    selectedItems: string;
    deleteSeries: string;
    edit: string;
    editSeries: string;
    editEvent: string;
    createEvent: string;
    subject: string;
    addTitle: string;
    moreDetails: string;
    save: string;
    editContent: string;
    deleteContent: string;
    deleteMultipleContent: string;
    newEvent: string;
    title: string;
    location: string;
    description: string;
    timezone: string;
    startTimezone: string;
    endTimezone: string;
    repeat: string;
    saveButton: string;
    cancelButton: string;
    deleteButton: string;
    recurrence: string;
    wrongPattern: string;
    seriesChangeAlert: string;
    createError: string;
    sameDayAlert: string;
    editRecurrence: string;
    repeats: string;
    alert: string;
    startEndError: string;
    invalidDateError: string;
    blockAlert: string;
    ok: string;
    yes: string;
    no: string;
    occurrence: string;
    series: string;
    previous: string;
    next: string;
    timelineDay: string;
    timelineWeek: string;
    timelineWorkWeek: string;
    timelineMonth: string;
    timelineYear: string;
    editFollowingEvent: string;
    deleteTitle: string;
    editTitle: string;
    beginFrom: string;
    endAt: string;
    expandAllDaySection: string;
    collapseAllDaySection: string;
}

export class RecurrenceEditor {
    constructor(none: string, daily: string, weekly: string, monthly: string, month: string, yearly: string,
                never: string, until: string, count: string, first: string, second: string, third: string,
                fourth: string, last: string, repeat: string, repeatEvery: string, on: string, end: string,
                onDay: string, days: string, weeks: string, months: string, years: string, every: string,
                summaryTimes: string, summaryOn: string, summaryUntil: string, summaryRepeat: string,
                summaryDay: string, summaryWeek: string, summaryMonth: string, summaryYear: string,
                monthWeek: string, monthPosition: string, monthExpander: string, yearExpander: string,
                repeatInterval: string) {

        this.none = none;
        this.daily = daily;
        this.weekly = weekly;
        this.monthly = monthly;
        this.month = month;
        this.yearly = yearly;
        this.never = never;
        this.until = until;
        this.count = count;
        this.first = first;
        this.second = second;
        this.third = third;
        this.fourth = fourth;
        this.last = last;
        this.repeat = repeat;
        this.repeatEvery = repeatEvery;
        this.on = on;
        this.end = end;
        this.onDay = onDay;
        this.days = days;
        this.weeks = weeks;
        this.months = months;
        this.years = years;
        this.summaryTimes = summaryTimes;
        this.summaryOn = summaryOn;
        this.summaryUntil = summaryUntil;
        this.summaryRepeat = summaryRepeat;
        this.summaryDay = summaryDay;
        this.summaryWeek = summaryWeek;
        this.summaryMonth = summaryMonth;
        this.summaryYear = summaryYear;
        this.monthWeek = monthWeek;
        this.monthPosition = monthPosition;
        this.monthExpander = monthExpander;
        this.yearExpander = yearExpander;
        this.repeatInterval = repeatInterval;
    }

    none: string;
    daily: string;
    weekly: string;
    monthly: string;
    month: string;
    yearly: string;
    never: string;
    until: string;
    count: string;
    first: string;
    second: string;
    third: string;
    fourth: string;
    last: string;
    repeat: string;
    repeatEvery: string;
    on: string;
    end: string;
    onDay: string;
    days: string;
    weeks: string;
    months: string;
    years: string;
    every: string;
    summaryTimes: string;
    summaryOn: string;
    summaryUntil: string;
    summaryRepeat: string;
    summaryDay: string;
    summaryWeek: string;
    summaryMonth: string;
    summaryYear: string;
    monthWeek: string;
    monthPosition: string;
    monthExpander: string;
    yearExpander: string;
    repeatInterval: string;
}

export class Datepicker {
    constructor(placeholder: string, today: string) {
        this.placeholder = placeholder;
        this.today = today;
    }

    placeholder: string;
    today: string;
}

export class Language {
    constructor(schedule: Schedule, recurrenceeditor: RecurrenceEditor, datepicker: Datepicker) {
        this.schedule = schedule;
        this.recurrenceeditor = recurrenceeditor;
        this.datepicker = datepicker;
    }

    schedule: Schedule;
    recurrenceeditor: RecurrenceEditor;
    datepicker: Datepicker;
}

export class TranslationSyncfusion {
    constructor(language: Language) {
        this['*LanguageToReplace*'] = language;
    }

    "*LanguageToReplace*": Language;
}