<div class="row m-0 mb-4">
    <div style="width: 200px;"></div>
    <div class="col-sm d-flex justify-content-center" style="color: #812e52;" *ngFor="let data of getWeekNamesAndDays([], '', '', [])">
        {{data.dayName}}
    </div>
</div>
<div *ngFor="let tc of teamCalendarData" class="row m-0 mb-3 align-items-center">
    <div class="kt-user-card-v2" style="width: 200px;">
        <div class="kt-user-card-v2__pic d-flex">
            <a class="notification">
                <ngx-avatar *ngIf="!tc.userProfilePicture" size="43" [round]="true" 
                            name="{{commonHelper.getFirstAndLastName(tc.name + ' ' + tc.surname)}}" 
                            bgColor="#7a7a7a"
                            (click)="getBackupPerson(tc.backupPerson, tc.haveBackupPerson, tc.allowanceDays, tc.name + ' ' + tc.surname)"
                            [popover]="popTemplateBP" triggers="click:mouseleave" 
                            placement="right" container="body">
                </ngx-avatar>
                <ngx-avatar *ngIf="tc.userProfilePicture" size="43" [round]="true" 
                            src="{{tc.userProfilePicture}}"
                            (click)="getBackupPerson(tc.backupPerson, tc.haveBackupPerson, tc.allowanceDays, tc.name + ' ' + tc.surname)"
                            [popover]="popTemplateBP" triggers="click:mouseleave" 
                            placement="right" container="body">
                </ngx-avatar>
                
                <span class="badge badge-left"
                      (click)="getBackupPerson(tc.backupPerson, tc.haveBackupPerson, tc.allowanceDays, tc.name + ' ' + tc.surname)"
                      [popover]="popTemplateBP" triggers="click:mouseleave" 
                      placement="right" container="body">
                    <i class="fa fa-sm fa-info"></i>
                </span>
            </a>
            <div class="d-flex flex-column justify-content-center ml-3"
                 (click)="getBackupPerson(tc.backupPerson, tc.haveBackupPerson, tc.allowanceDays, tc.name + ' ' + tc.surname)"
                 [popover]="popTemplateBP" triggers="click:mouseleave" 
                 placement="right" container="body">
                <a class="ml-1" style="color: #812e52;">{{tc.name}} {{tc.surname}}</a>
            </div>
        </div>
    </div>

    <ng-container *ngFor="let data of tc.datasTeamCalendar">
        
        <span class="col-sm dayNumber d-flex justify-content-center" (click)="getCurrentPopoverContextData(data.event)" [style.opacity]="data.haveEvent && data.isWeekend ? '0.6' : '1'" [style.color]="data.haveEvent ? 'white' : 'inherit'" [style.backgroundColor]="data.event.color" [ngClass]="getDayClass(data)" *ngIf="data.haveEvent" [popover]="popTemplate" triggers="click:mouseleave" placement="right" container="body">
            {{data.dayNumber}}
        </span>
        <span class="col-sm dayNumber d-flex justify-content-center" [ngClass]="getDayClass(data)" *ngIf="!data.haveEvent">
            {{data.dayNumber}}
        </span>
    </ng-container>

</div>

<ng-template #popTemplateBP>
    <b>{{fullName}}</b>
    <div class="d-flex">
        <span><b *ngIf="haveBackupPerson">{{backupPersonTitle}}:</b></span>
        <span>&nbsp;{{backupPerson}}</span>
    </div>
    <div *ngIf="allowanceDaysText" class="d-flex">
        <span><b>{{holidaysLeftTitle}}:</b></span>
        <span>&nbsp;{{allowanceDaysText}}</span>
    </div>
</ng-template>


<ng-template #popTemplate>
    <div>{{currentPopoverContext.name}} {{currentPopoverContext.surname}}</div>
    <div>{{currentPopoverContext.event}}</div>
    <div>{{currentPopoverContext.startingDT | date:pipeBaseDateFormat}} - {{currentPopoverContext.endingDT | date:pipeBaseDateFormat}}</div>
</ng-template>
