<div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__body kt-portlet__body--fit" [perfectScrollbar]>
        <div class="row row-no-padding row-col-separator-xl">
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                Total Profit
                            </h4>
                            <span class="kt-widget24__desc">
                                All Customs Value
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt--font-brand">
                            $<span counto [step]="30" [duration]="1" [countFrom]="0"
                                [countTo]="dashboardTopStats.totalProfit"
                                (countoChange)="dashboardTopStats.totalProfitCounter = $event">
                                {{dashboardTopStats.totalProfitCounter.toFixed(0)}}
                            </span>
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-brand" role="progressbar" style="width: 76%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            Change
                        </span>
                        <span class="kt-widget24__number">
                            <span class="counterup">{{dashboardTopStats.totalProfitChange}}</span>%
                        </span>
                    </div>
                </div>

            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                New Feedbacks
                            </h4>
                            <span class="kt-widget24__desc">
                                Customer Review
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt--font-info" counto [step]="30" [duration]="1"
                            [countFrom]="0" [countTo]="dashboardTopStats.newFeedbacks"
                            (countoChange)="dashboardTopStats.newFeedbacksCounter = $event">
                            {{dashboardTopStats.newFeedbacksCounter.toFixed(0)}}
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-info" role="progressbar" style="width: 85%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            Change
                        </span>
                        <span class="kt-widget24__number">
                            <span class="counterup">{{dashboardTopStats.newFeedbacksChange}}</span>%
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                New Orders
                            </h4>
                            <span class="kt-widget24__desc">
                                Fresh Order Amount
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt--font-info" counto [step]="30" [duration]="1"
                            [countFrom]="0" [countTo]="dashboardTopStats.newOrders"
                            (countoChange)="dashboardTopStats.newOrdersCounter = $event">
                            {{dashboardTopStats.newOrdersCounter.toFixed(0)}}
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-danger" role="progressbar" style="width: 45%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="kt-widget24__action">
                        <span class="kt-widget24__change">
                            Change
                        </span>
                        <span class="kt-widget24__number">
                            <span class="counterup">{{dashboardTopStats.newOrdersChange}}</span>%
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-3">
                <div class="kt-widget24">
                    <div class="kt-widget24__details">
                        <div class="kt-widget24__info">
                            <h4 class="kt-widget24__title">
                                New Users
                            </h4>
                            <span class="kt-widget24__desc">
                                Joined New User
                            </span>
                        </div>
                        <span class="kt-widget24__stats kt--font-info" counto [step]="30" [duration]="1"
                            [countFrom]="0" [countTo]="dashboardTopStats.newUsers"
                            (countoChange)="dashboardTopStats.newUsersCounter = $event">
                            {{dashboardTopStats.newUsersCounter.toFixed(0)}}
                        </span>
                    </div>
                    <div class="progress progress--sm">
                        <div class="progress-bar kt-bg-success" role="progressbar" style="width: 57%;"
                            aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span class="kt-widget24__change">
                        Change
                    </span>
                    <span class="kt-widget24__number">
                        <span class="counterup">{{dashboardTopStats.newUsersChange}}</span>%
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>