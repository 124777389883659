<ng-template #popTemplate>
        <div>{{modalData.event.title}}</div>
        <div>{{modalData.event.start | date:'dd/MM/yyyy'}} - {{modalData.event.end | date:'dd/MM/yyyy'}}</div>
</ng-template>

<ng-template #customCellTemplate let-day="day">
<span *ngIf="day.events.length > 0" (click)="handleEvent('clicked', day.events[0])" [popover]="popTemplate"
        placement="right"
        triggers="click:mouseleave"
        container="body" style="height: 100%;" [ngClass]="day.events.length > 0 ? 'myNumberStyle' : ''" [style.background]="day.events.length > 0 ? day.events[0].color.primary : 'inherit'">
        <div class="cal-cell-top">
        <!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
        >{{ day.badgeTotal }}</span
        > -->
        <span class="cal-day-number"
        >{{ day.date | calendarDate:'monthViewDayNumber' }}</span
        >
        </div>
        <!-- <small style="margin: 5px;"
        >There are {{ day.events.length }} events on this day</small
        > -->
</span>
<span *ngIf="day.events.length === 0" style="height: 100%;">
        <div class="cal-cell-top">
        <!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0"
        >{{ day.badgeTotal }}</span
        > -->
        <span class="cal-day-number"
        >{{ day.date | calendarDate:'monthViewDayNumber' }}</span
        >
        </div>
        <!-- <small style="margin: 5px;"
        >There are {{ day.events.length }} events on this day</small
        > -->
</span>
</ng-template>

<mwl-calendar-month-view #mv 
                        [viewDate]="viewDate"
                        [events]="events"
                        [refresh]="refresh"
                        [activeDayIsOpen]="false"
                        (dayClicked)="dayClicked($event.day)"
                        (eventClicked)="handleEvent('Clicked', $event.event)"
                        (eventTimesChanged)="eventTimesChanged($event)"
                        [cellTemplate]="customCellTemplate"
                        weekStartsOn="1"
                        [weekendDays]="daysOff">
</mwl-calendar-month-view>