<div [@routerTransition]>
    <div id="kt_header_mobile" class="kt-header-mobile">
        <div class="kt-header-mobile__logo">
            <a routerLink="/" target="_blank" class="light">
                <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/Logo_Slogan_Azul_Trans_32.png" alt="logo" height="100" />
                <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId" alt="logo" height="100" />
            </a>
        </div>
        <div class="kt-header-mobile__toolbar">
            <button class="kt-header-mobile__toolbar-toggler" id="kt_header_mobile_toggler">
                <span></span>
            </button>
            <button ktToggle [options]="userMenuToggleOptions" class="kt-header-mobile__toolbar-topbar-toggler" id="kt_header_mobile_topbar_toggler">
                <i class="flaticon-more-1"></i>
            </button>
        </div>
    </div>
    <div class="kt-grid kt-grid--hor kt-grid--root">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                <div id="kt_header" ktHeader #ktHeader class="kt-header kt-grid__item" data-ktheader-minimize="1">
                    <div [class]="containerClass">
                        <theme4-brand></theme4-brand>

                        <top-bar-menu style="width: 100%"></top-bar-menu>

                        <topbar></topbar>
                    </div>
                    <div class="d-lg-none p-3 mobile-subscription-warning">
                        <div *ngIf="subscriptionStatusBarVisible()"
                             [ngClass]="{'hidden': !subscriptionStatusBarVisible()}" class="d-inline-block d-sm-none mt-3 ml-3">
                            <span *ngIf="tenant.isInTrialPeriod && !subscriptionIsExpiringSoon()" [innerHTML]="getTrialSubscriptionNotification()"></span>
                            <a *ngIf="subscriptionIsExpiringSoon()" routerLink="/app/admin/subscription-management">
                                <span *ngIf="subscriptionIsExpiringSoon() && tenant.isInTrialPeriod"><i class="fa fa-exclamation-circle"></i> {{getExpireNotification("TrialExpireNotification")}}</span>
                                <span *ngIf="subscriptionIsExpiringSoon() && !tenant.isInTrialPeriod"><i class="fa fa-exclamation-circle"></i> {{getExpireNotification("SubscriptionExpireNotification")}}</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
                    <div class="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch" id="kt_body">
                        <div class="kt-content kt-content--fit-top  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
                <stacked-footer-bar></stacked-footer-bar>
            </div>
        </div>
    </div>
    <kt-scroll-top></kt-scroll-top> 
</div>
