<div appBsModal #addProfileDetailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form *ngIf="active" #addProfileDetailModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"MyProfile" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-2">
                           <!-- <img [src]="!user.getProfilePictureOutput.profilePicture" [style.border]="user.getProfilePictureOutput.profilePicture ? '1px solid black' : 'none'" />-->
                            <ngx-avatar *ngIf="!user.getProfilePictureOutput.profilePicture" size="100" name="{{commonHelper.getFirstAndLastName(getNameAndSurname)}}" bgColor="#7a7a7a"></ngx-avatar>
                            <ngx-avatar *ngIf="user.getProfilePictureOutput.profilePicture" size="100" src="{{user.getProfilePictureOutput.profilePicture}}"></ngx-avatar>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <input type="file" (change)="fileChangeEvent($event)" />
                            </div>
                            <span class="help-block m-b-none">{{"ProfilePicture_Change_Info" | localize:maxProfilPictureBytesUserFriendlyValue}}</span>

                        </div>

                        <div class="col-sm-4">
                            <image-cropper [imageChangedEvent]="imageChangedEvent"
                                           [maintainAspectRatio]="true"
                                           [aspectRatio]="4 / 4"
                                           [resizeToWidth]="128"
                                           format="png"
                                           (imageCropped)="imageCroppedFile($event)"></image-cropper>
                        </div>


                    </div>

                    <hr size="1" noshade>

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="Name">{{"Name" | localize}} </label>
                                <input id="Name" (focus)="name=true" (blur)="name=false" #nameInput="ngModel" class="form-control" type="text" name="Name" [(ngModel)]="user.currentUserProfileEditDto.name" maxlength="64">
                                <span *ngIf="user.currentUserProfileEditDto.name">{{user.currentUserProfileEditDto.name?.length}}/64</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="Surname">{{"Surname" | localize}} </label>
                                <input id="Surname" (focus)="surname=true" (blur)="surname=false" #surnameInput="ngModel" type="text" name="Surname" class="form-control" [(ngModel)]="user.currentUserProfileEditDto.surname" maxlength="64">
                                <span *ngIf="user.currentUserProfileEditDto.surname">{{user.currentUserProfileEditDto.surname?.length}}/64</span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="PrefersToBeCalledByName">{{"PrefersToBeCalledByName" | localize}} </label>
                                <input id="PrefersToBeCalledByName" (focus)="prefersToBeCalledByName=true" (blur)="prefersToBeCalledByName=false" #PrefersToBeCalledByNameInput="ngModel" class="form-control" type="text" name="PrefersToBeCalledByName" [(ngModel)]="user.prefersToBeCalledByName" maxlength="50">
                                <span *ngIf="user.prefersToBeCalledByName">{{user.prefersToBeCalledByName?.length}}/50</span>
                            </div>

                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label for="EmployeeNumber">{{"EmployeeNumber" | localize}} </label>
                                <input id="EmployeeNumber" (focus)="employeeNumber=true" (blur)="employeeNumber=false" #EmployeeNumberInput="ngModel" class="form-control" type="text" name="EmployeeNumber" [(ngModel)]="user.employeeNumber" readonly maxlength="50">
                                <!-- <span *ngIf="user.employeeNumber">{{user.employeeNumber?.length}}/50</span> -->
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label for="Title">{{"Title" | localize}} </label>
                                <input id="Title" (focus)="title=true" (blur)="title=false" #TitleInput="ngModel" class="form-control" type="text" name="Title" [(ngModel)]="user.title" maxlength="50">
                                <span *ngIf="user.title">{{user.title?.length}}/50</span>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div class="form-group">
                                <label for="EmailAddress">{{"EmailAddress" | localize}} </label>
                                <input id="EmailAddress" (focus)="show=true" (blur)="show=false" #emailAddressInput="ngModel" type="email" name="EmailAddress" class="form-control" [(ngModel)]="user.currentUserProfileEditDto.emailAddress" readonly email maxlength="256">
                             </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="BackupPerson">{{"BackupPerson" | localize}} </label>
                                <input id="BackupPerson" #BackupPersonInput="ngModel" class="form-control" type="text" name="BackupPerson" [(ngModel)]="user.bp" readonly maxlength="50">
                                <!-- <span *ngIf="user.employeeNumber">{{user.employeeNumber?.length}}/50</span> -->
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="JobTitle">{{"JobTitle" | localize}} </label>
                                <input id="JobTitle" (focus)="jobTitle=true" (blur)="jobTitle=false" #JobTitleInput="ngModel" class="form-control" type="text" name="JobTitle" [(ngModel)]="user.jobTitle" readonly maxlength="50">
                                <!-- <span *ngIf="user.jobTitle">{{user.jobTitle?.length}}/50</span> -->
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="Department">{{"Department" | localize}} </label>
                                <input id="Department" (focus)="department=true" (blur)="department=false" #DepartmentInput="ngModel" class="form-control" type="text" name="Department" [(ngModel)]="user.departmentName" readonly maxlength="50">
                                <!-- <span *ngIf="user.departmentName">{{user.departmentName?.length}}/50</span> -->

                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="LineManager">{{"LineManager" | localize}} </label>
                                <input id="LineManager" (focus)="show=true" (blur)="show=false" class="form-control" type="text" name="LineManager" value="{{user.lineManagerDesc}}" maxlength="50" readonly>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="Country">{{"Country" | localize}} </label>
                                <!--<input id="Country" #CountryInput="ngModel" class="form-control" type="text" name="Country" [(ngModel)]="user.countryId" readonly>-->
                                <select class="form-control" id="Country" name="countries" [(ngModel)]="user.countryId">
                                    <option value={{country.id}} *ngFor="let country of user.countries">{{country.name}}</option>
                                </select>

                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="Site">{{"Site" | localize}} </label>
                                <input id="Site" (focus)="site=true" (blur)="site=false" #SiteInput="ngModel" class="form-control" type="text" name="Site" [(ngModel)]="user.site" maxlength="50">
                                <span *ngIf="user.site">{{user.site?.length}}/50</span>

                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label for="PhoneNumber">{{"PhoneNumber" | localize}} </label>
                                <input id="PhoneNumber" (focus)="phoneNumber=true" (blur)="phoneNumber=false" #PhoneNumberInput="ngModel" class="form-control" type="text" name="PhoneNumber" [(ngModel)]="user.currentUserProfileEditDto.phoneNumber" maxlength="24">
                                <span *ngIf="user.currentUserProfileEditDto.phoneNumber">{{user.currentUserProfileEditDto.phoneNumber?.length}}/24</span>

                            </div>
                        </div>
                        <div class="col-sm-1">
                            <label for="PhoneExtensionNumber">{{"Ext" | localize}} </label>
                            <input id="PhoneExtensionNumber" (focus)="phoneExtensionNumber=true" (blur)="phoneExtensionNumber=false" #PhoneExtensionNumberInput="ngModel" class="form-control" type="text" name="PhoneExtensionNumber" [(ngModel)]="user.phoneExtensionNumber" maxlength="24">
                            <span *ngIf="user.phoneExtensionNumber">{{user.phoneExtensionNumber?.length}}/24</span>

                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label for="MobileNumber">{{"MobileNumber" | localize}} </label>
                                <input id="MobileNumber" (focus)="mobileNumber=true" (blur)="mobileNumber=false" #MobileNumberInput="ngModel" class="form-control" type="text" name="MobileNumber" [(ngModel)]="user.mobileNumber" maxlength="50">
                                <span *ngIf="user.mobileNumber">{{user.mobileNumber?.length}}/50</span>
                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="JoinedTheCompanyOnDate">{{"JoinedTheCompanyOnDate" | localize}} </label>
                                <input id="JoinedTheCompanyOnDate"
                                       type="text"
                                       #JoinedTheCompanyOnDate
                                       name="JoinedTheCompanyOnDate"
                                       class="form-control"
                                       bsDatepicker
                                       datePickerMomentModifier
                                       [(date)]="user.joinedTheCompanyOnDate"
                                       [bsConfig]="bsConfig" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="JoinedTheDepartmentOnDate">{{"JoinedTheDepartmentOnDate" | localize}} </label>
                                <input id="JoinedTheDepartmentOnDate"
                                       type="text"
                                       #JoinedTheDepartmentOnDate
                                       name="JoinedTheDepartmentOnDate"
                                       class="form-control"
                                       bsDatepicker
                                       datePickerMomentModifier
                                       [(date)]="user.joinedTheDepartmentOnDate"
                                       [bsConfig]="bsConfig" />
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="DateOfBirth">{{"DateOfBirth" | localize}} </label>
                                <input id="DateOfBirth"
                                       type="text"
                                       #SampleDatePicker
                                       name="DateOfBirth"
                                       class="form-control"
                                       bsDatepicker
                                       datePickerMomentModifier
                                       [(date)]="user.dateOfBirth"
                                       [bsConfig]="bsConfig" />
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="LinkedInProfileUrl">{{"LinkedInProfileUrl" | localize}} </label>
                                <input id="LinkedInProfileUrl" (focus)="linkedInProfileUrl=true" (blur)="linkedInProfileUrl=false" #LinkedInProfileUrlInput="ngModel" class="form-control" type="text" name="LinkedInProfileUrl" [(ngModel)]="user.linkedInProfileUrl" maxlength="200">
                                <span *ngIf="user.linkedInProfileUrl">{{user.linkedInProfileUrl?.length}}/200</span>

                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="TwitterProfileUrl">{{"TwitterProfileUrl" | localize}} </label>
                                <input id="TwitterProfileUrl" (focus)="twitterProfileUrl=true" (blur)="twitterProfileUrl=false" #TwitterProfileUrlInput="ngModel" class="form-control" type="text" name="TwitterProfileUrl" [(ngModel)]="user.twitterProfileUrl" maxlength="50">
                                <span *ngIf="user.twitterProfileUrl">{{user.twitterProfileUrl?.length}}/50</span>

                            </div>
                        </div>
                    </div>

                    <hr size="1" noshade>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="MoreAboutMe">{{"MoreAboutMe" | localize}} </label>
                                <textarea id="MoreAboutMe" (focus)="moreAboutMe=true" (blur)="moreAboutMe=false" #MoreAboutMeInput="ngModel" class="form-control" type="text" name="MoreAboutMe" [(ngModel)]="user.moreAboutMe" maxlength="4000"></textarea>
                                <span *ngIf="user.moreAboutMe">{{user.moreAboutMe?.length}}/4000</span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="Hobbies">{{"Hobbies" | localize}} </label>
                                <textarea id="Hobbies" (focus)="hobbies=true" (blur)="hobbies=false" #HobbiesInput="ngModel" class="form-control" type="text" name="Hobbies" [(ngModel)]="user.hobbies" maxlength="100"></textarea>
                                <span *ngIf="user.hobbies">{{user.hobbies?.length}}/100</span>

                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="FavouriteSportsTeam">{{"FavouriteSportsTeam" | localize}} </label>
                                <input id="FavouriteSportsTeam" (focus)="favouriteSportsTeam=true" (blur)="favouriteSportsTeam=false" #FavouriteSportsTeam="ngModel" class="form-control" type="text" name="FavouriteSportsTeam" [(ngModel)]="user.favouriteSportsTeam" maxlength="100">
                                <span *ngIf="user.favouriteSportsTeam">{{user.favouriteSportsTeam?.length}}/100</span>

                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="FavouriteBandsOrMusics">{{"FavouriteBandsOrMusics" | localize}} </label>
                                <input id="FavouriteBandsOrMusics" (focus)="favouriteBandsOrMusics=true" (blur)="favouriteBandsOrMusics=false" #FavouriteBandsOrMusics="ngModel" class="form-control" type="text" name="FavouriteBandsOrMusics" [(ngModel)]="user.favouriteBandsOrMusics" maxlength="100">
                                <span *ngIf="user.favouriteBandsOrMusics">{{user.favouriteBandsOrMusics?.length}}/100</span>

                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label for="FavouriteArtistsOrMovies">{{"FavouriteArtistsOrMovies" | localize}} </label>
                                <input id="FavouriteArtistsOrMovies" (focus)="favouriteArtistsOrMovies=true" (blur)="favouriteArtistsOrMovies=false" #FavouriteArtistsOrMovies="ngModel" class="form-control" type="text" name="FavouriteArtistsOrMovies" [(ngModel)]="user.favouriteArtistsOrMovies" maxlength="100">
                                <span *ngIf="user.favouriteArtistsOrMovies">{{user.favouriteArtistsOrMovies?.length}}/100</span>

                            </div>
                        </div>
                    </div>



                    <!--<div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="ExternalContactCompanyName">{{"ExternalContactCompanyName" | localize}} </label>
                                <input id="ExternalContactCompanyName" #ExternalContactCompanyName="ngModel" class="form-control" type="text" name="ExternalContactCompanyName" [(ngModel)]="user.externalContactCompanyName"  maxlength="50">
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="ExternalContactTypeId">{{"ExternalContactTypeId" | localize}} </label>
                                <input id="ExternalContactTypeId" #ExternalContactTypeId="ngModel" class="form-control" type="text" name="ExternalContactTypeId" [(ngModel)]="user.externalContactTypeId" readonly>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="ExternalContactWebsite">{{"ExternalContactWebsite" | localize}} </label>
                                <input id="ExternalContactWebsite" #ExternalContactWebsite="ngModel" class="form-control" type="text" name="ExternalContactWebsite" [(ngModel)]="user.externalContactWebsite"  maxlength="200">
                            </div>
                        </div>
                    </div>-->
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()" [disabled]="saving">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="saving"><i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>
