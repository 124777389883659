import { Component, Injector, ViewChild, ViewEncapsulation, AfterViewInit, Input } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestTimeOffProxy, GetLeaveRequestsDto, LeaveRequestsDto } from '@shared/service-proxies/service-proxies';
import { LazyLoadEvent } from 'primeng/public_api';
import { Paginator } from 'primeng/paginator';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { CommonHelper } from '@shared/helpers/CommonHelper';
import { RejectionReasonModalComponent } from './rejection-reason-modal.component';

@Component({
    selector: 'app-leave-requests-base',
    templateUrl: './leave-requests-base.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./leave-requests-base.component.less'],
    animations: [appModuleAnimation()]
})
export class LeaveRequestsBaseComponent extends AppComponentBase implements AfterViewInit {

    @ViewChild('rejectionReasonModal', {static: true}) rejectionReasonModal: RejectionReasonModalComponent;
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;

    leaveRequests = new GetLeaveRequestsDto();

    isApprovedSelected = false;
    isRejectedSelected = false;
    isCancelledSelected = false;

    selectedOption = 0;

    @Input() fromDashboard: boolean;

    commonHelper = CommonHelper;

    constructor(
        injector: Injector,
        private _requestTimeOffServiceProxy: RequestTimeOffProxy
    ) {
        super(injector);
    }

    ngAfterViewInit(): void {
        abp.event.on('app.pendingLeaveRequests', () => {
            this.getLeaveRequests(null, 0);
        });
        this.primengTableHelper.adjustScroll(this.dataTable);
    }

    getLeaveRequests(event?: LazyLoadEvent, leaveStatus?: number) {
        this.primengTableHelper.showLoadingIndicator();

        this._requestTimeOffServiceProxy.getLeaveRequests(this.selectedOption)
        .pipe(finalize(() => this.primengTableHelper.hideLoadingIndicator())).subscribe(result => {
            this.leaveRequests = result;
            this.setDataToTable<LeaveRequestsDto>(this.leaveRequests.leaveRequests);

            this.leaveRequests.status.forEach(x => x.selected = false);
            if (!this.isApprovedSelected && !this.isRejectedSelected && !this.isCancelledSelected) {
                this.leaveRequests.status[0].selected = true;
                this.selectedOption = 0;
            } else if (this.isApprovedSelected) {
                this.leaveRequests.status[1].selected = true;
                this.selectedOption = 1;
            } else if (this.isRejectedSelected) {
                this.leaveRequests.status[2].selected = true;
                this.selectedOption = 2;
            }else if (this.isCancelledSelected){
                this.leaveRequests.status[3].selected = true;
                this.selectedOption = 3;
            } else {
                this.leaveRequests.status[0].selected = true;
                this.selectedOption = 0;
            }

            this.primengTableHelper.hideLoadingIndicator();
        });
    }

    onChangeLS(id: number) {
        if (!id || Number(id) === 0) return;

        this.leaveRequests.status.forEach(x => x.selected = false);
        let ls = this.leaveRequests.status.filter(x => x.id === Number(id))[0];
        if (ls) {
            ls.selected = true;
            switch(ls.name) {
                case 'Pending':
                  this.isApprovedSelected = false;
                  this.isRejectedSelected = false;
                  this.isCancelledSelected = false;
                  this.selectedOption = 0;
                  this.getLeaveRequests(null, 0);
                  break;
                case 'Approved':
                  this.isApprovedSelected = true;
                  this.isRejectedSelected = false;
                  this.isCancelledSelected = false;
                  this.selectedOption = 1;
                  this.getLeaveRequests(null, 1);
                  break;
                case 'Rejected':
                  this.isRejectedSelected = true;
                  this.isApprovedSelected = false;
                  this.isCancelledSelected = false;
                  this.selectedOption = 2;
                  this.getLeaveRequests(null, 2);
                  break;
                case 'Cancelled':
                  this.isRejectedSelected = false;
                  this.isApprovedSelected = false;
                  this.isCancelledSelected = true;
                  this.selectedOption = 3;
                  this.getLeaveRequests(null, 3);
                  break;
                default:
                  this.isApprovedSelected = false;
                  this.isRejectedSelected = false;
                  this.isCancelledSelected = false;
                  this.selectedOption = 0;
                  this.getLeaveRequests(null, 0);
                  break;
              }
        }
    }

    approve(leaveRequest: LeaveRequestsDto) {
        if (!leaveRequest || leaveRequest.isApproved) return;

        if (!leaveRequest.haveConflits) {
            this.doFinalApprove(leaveRequest);
            return;
        }

        this.message.confirm(
            '',
            this.l('ThereAreMoreUsersWithApprovedRequestsSameDateRangeContinue'),
            isConfirmed => {
                if (isConfirmed) this.doFinalApprove(leaveRequest);
            }
        );
    }

    reject(leaveRequest: LeaveRequestsDto) {
        if (!leaveRequest || leaveRequest.isRejected) return;

        this.rejectionReasonModal.show();

        this.rejectionReasonModal.modalSave.subscribe((rejectionReason: string) => {
            leaveRequest.leaveReason = rejectionReason;

            this._requestTimeOffServiceProxy.rejectLeaveRequest(leaveRequest).subscribe(() => {
                this.onSuccessAction(leaveRequest);
            });
        });
    }

    private doFinalApprove(leaveRequest: LeaveRequestsDto) {
        if (!leaveRequest || leaveRequest.isApproved) return;

        this._requestTimeOffServiceProxy.approveLeaveRequest(leaveRequest).subscribe(() => {
            this.onSuccessAction(leaveRequest);
        });
    }

    private onSuccessAction(leaveRequest: LeaveRequestsDto) {
        abp.notify.success(this.l('SavedSuccessfully'));
        this.removeRecordFromTable(leaveRequest);
        abp.event.trigger('app.notifications.refresh');
    }

    openTeamCalendar(url: string){
        window.open(url, "_blank");
    }

    get haveAnyConflit(): boolean {
        return this.primengTableHelper && this.primengTableHelper.records && 
               this.primengTableHelper.records.filter(x => x.leaveStatus !== 'Approved' && x.haveConflits).length > 0;
    }

    private removeRecordFromTable(leaveRequest: LeaveRequestsDto) {
        if (!leaveRequest) return;

        const index = this.leaveRequests.leaveRequests.indexOf(leaveRequest);
        if (index !== -1) {
            this.leaveRequests.leaveRequests.splice(index, 1);
        } 
    }
}
