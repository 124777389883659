<div [@routerTransition]>
    <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div class="kt-subheader kt-grid__item">
            <div [class]="containerClass">
                <div class="kt-subheader__main">
                    <h3 class="kt-subheader__title">
                        <span>{{"Notifications" | localize}}</span>
                    </h3>
                </div>
                <div class="kt-subheader__toolbar">
                    <div class="kt-subheader__wrapper">
                        <button class="btn btn-success" (click)="openNotificationSettingsModal()"><i
                                class="fa fa-cog"></i>
                            {{"NotificationSettings" | localize}}</button>
                        <button class="btn btn-primary" (click)="setAllNotificationsAsRead()"><i
                                class="fa fa-check"></i>
                            {{"SetAllAsRead" | localize}}</button>
                    </div>
                </div>
            </div>
        </div>
        <div [class]="containerClass + ' kt-grid__item kt-grid__item--fluid'">
            <div class="kt-portlet">
                <div class="kt-portlet__body">
                    <div class="kt-form">
                        <div class="row mb-4">
                            <div class="col-xl-6">
                                <input class="form-control" id="StartEndRange" name="StartEndRange"
                                    #drp="bsDaterangepicker" readonly="readonly" bsDaterangepicker
                                    dateRangePickerMomentModifier [(date)]="dateRange">
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <select #TargetValueFilterSelectionCombobox class="form-control"
                                        [(ngModel)]="readStateFilter" name="readStateFilter">
                                        <option value="ALL">{{"All" | localize}}</option>
                                        <option value="UNREAD">{{"Unread" | localize}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12 text-right">
                                <div class="form-group">
                                    <button class="btn btn-danger" (click)="deleteNotifications()"><i
                                            class="la la-times"></i>{{"DeleteListedNotifications" | localize}}</button>
                                    <button class="btn btn-primary" (click)="getNotifications()"><i
                                            class="fa fa-sync"></i>
                                        {{"Refresh" | localize}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <!--<Primeng-TurboTable-Start>-->
                        <div class="col primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                            <p-table #dataTable (onLazyLoad)="getNotifications($event)"
                                [value]="primengTableHelper.records"
                                rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false"
                                [lazy]="true" [responsive]="primengTableHelper.isResponsive">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:130px">
                                            {{'Actions' | localize}}
                                        </th>
                                        <th>
                                            {{'Notification' | localize}}
                                        </th>
                                        <th>
                                            {{'CreationTime' | localize}}
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-record="$implicit">
                                    <tr>
                                        <td style="width:130px">
                                            <button class="btn btn-sm btn-icon btn-primary"
                                                [attr.title]="!isRead(record) ? l('SetAsRead') : ''"
                                                (click)="setAsRead(record)" [disabled]="isRead(record)">
                                                <i class="fa fa-check" *ngIf="isRead(record)"
                                                    [attr.aria-label]="l('Read')"></i>
                                                <i class="fa fa-circle-notch" *ngIf="!isRead(record)"
                                                    [attr.aria-label]="l('Unread')"></i>
                                            </button>
                                            <button class="btn btn-sm btn-icon btn-danger" [attr.title]="l('Delete')"
                                                (click)="deleteNotification(record)">
                                                <i class="fa fa-times" [attr.aria-label]="l('Delete')"></i>
                                            </button>
                                        </td>
                                        <td>
                                            <span class="ui-column-title">{{'Notification' | localize}}</span>
                                            <a *ngIf="record.formattedNotification.url"
                                                href="{{record.formattedNotification.url}}"
                                                class="{{getRowClass(record)}}">{{truncateString(record.formattedNotification.text, 120)}}</a>
                                            <span *ngIf="!record.formattedNotification.url"
                                                title="{{record.formattedNotification.text}}"
                                                class="{{getRowClass(record)}}">{{truncateString(record.formattedNotification.text, 120)}}</span>
                                        </td>
                                        <td>
                                            <span title="{{record.notification.creationTime | momentFormat:'llll'}}"
                                                class="{{getRowClass(record)}}">
                                                {{fromNow(record.notification.creationTime)}}
                                            </span>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                {{'NoData' | localize}}
                            </div>
                            <div class="primeng-paging-container">
                                <p-paginator [rows]="primengTableHelper.defaultRecordsCountPerPage" #paginator
                                    (onPageChange)="getNotifications($event)"
                                    [totalRecords]="primengTableHelper.totalRecordsCount"
                                    [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                                </p-paginator>
                                <span class="total-records-count">
                                    {{'TotalRecordsCount' | localize:primengTableHelper.totalRecordsCount}}
                                </span>
                            </div>
                        </div>
                        <!--<Primeng-TurboTable-End>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>