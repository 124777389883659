<div class="kt-header__brand kt-grid__item" id="kt_header_brand">
    <div class="kt-header__brand-logo">
        <a routerLink="/" target="_blank" class="light">
            <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/Logo_Slogan_Azul_Trans_32.png" alt="logo" height="100" />
            <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId" alt="logo" height="100" />
        </a>
        <a routerLink="/" target="_blank" class="dark">
            <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" src="/assets/common/images/Logo_Slogan_Branco_Trans_32.png" alt="logo" height="100" />
            <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId" alt="logo" height="100" />
        </a>
    </div>
</div>
