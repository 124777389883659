import { Component, OnInit, Injector, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { DayService, WeekService, WorkWeekService, MonthService, AgendaService, EventSettingsModel } from '@syncfusion/ej2-angular-schedule';
import * as moment from 'moment';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/public_api';
import { CommonHelper } from '@shared/helpers/CommonHelper';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TeamCalendarBaseComponent } from '@app/shared/common/team-calendar-base/team-calendar-base.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CalendarServiceProxy, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { appModuleAnimation } from '@shared/animations/routerTransition';


@Component({
    selector: 'app-widget-team-calendar',
    templateUrl: './widget-team-calendar.component.html',
    styleUrls: ['./widget-team-calendar.component.css'],
    providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService],
    encapsulation: ViewEncapsulation.None,
    animations: [appModuleAnimation()]
})
export class WidgetTeamCalendarComponent extends AppComponentBase implements OnInit {

    @ViewChild('tc') teamCalendarBase: TeamCalendarBaseComponent;

    constructor(
        injector: Injector, private _calendarServiceProxy: CalendarServiceProxy, private _userServiceProxy: UserServiceProxy,
        private route: ActivatedRoute) {
        super(injector);

    }

    ngOnInit(): void {
    }

}
