<div appBsModal #rejectionReasonModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="rejectionReasonModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: !saving}">
    <div class="modal-dialog modal-dialog-centered modal-lg text-center" style="width: 416px;">

        <div class="modal-content">
            <form #rejectionReasonModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header" style="padding-left: 15%;">
                    <h5 class="modal-title">
                        <span>{{"WhyAreYouRejectingRequest" | localize}}</span>
                    </h5>
                    <button type="button" class="close" (click)="close()" [attr.aria-label]="l('Close')"
                        [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body">
                    <div class="form-group mb-0 text-left">
                        <label for="Name">{{"EnterRequestRejectionReason" | localize}}</label>
                        <textarea #rejectionReasonInput="ngModel" style="resize: none;" class="form-control" name="rejectionReason" [(ngModel)]="rejectionReason" maxlength="255" rows=5></textarea>
                        <span>{{rejectionReason ? rejectionReason.length : 0}}/255</span>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" [disabled]="saving" class="btn btn-secondary"
                        (click)="close()">{{"Cancel" | localize}}</button>
                    <button type="submit" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"
                        class="btn btn-primary"
                        [disabled]="saving">
                        <i class="fa fa-save"></i> <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>