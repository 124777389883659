<div class="kt-portlet kt-portlet--height-fluid sales-summary-chart">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{"PendingLeaveRequests" | localize}}
            </h3>
            <a href="app/main/leave-requests">
                &nbsp;<i class="fas fa-external-link-alt"></i>
            </a>
        </div>
    </div>
    <div class="kt-portlet__body" [perfectScrollbar]>
        <app-leave-requests-base #lr [fromDashboard]="true"></app-leave-requests-base>
    </div>
</div>
