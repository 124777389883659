<div class="row m-0 align-items-center">
    <!--<Primeng-TurboTable-Start>-->
    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
        <p-table #dataTable (onLazyLoad)="getLeaveRequests($event)" [value]="primengTableHelper.records"
            [paginator]="false" [lazy]="true" [scrollable]="true" ScrollWidth="100%"
            [responsive]="primengTableHelper.isResponsive"
            [resizableColumns]="primengTableHelper.resizableColumns">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 200px">
                        {{'User' | localize}}
                    </th>
                    <th style="width: 100px">
                        {{'Type' | localize}}
                    </th>
                    <th style="width: 150px">
                        {{'From' | localize}}
                    </th>
                    <th style="width: 150px">
                        {{'To' | localize}}
                    </th>
                    <th *ngIf="!fromDashboard" style="width: 200px">
                        {{'Description' | localize}}
                    </th>
                    <th *ngIf="!fromDashboard" style="width: 40px"></th>
                    <th *ngIf="!fromDashboard && (!isApprovedSelected || !isApprovedSelected && !isRejectedSelected)" style="width: 80px;"></th>
                    <th *ngIf="!fromDashboard && (!isRejectedSelected || !isApprovedSelected && !isRejectedSelected)" style="width: 80px;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-record="$implicit">
                <tr>
                    <td style="width: 200px">
                        <div class="d-flex align-items-center kt-user-card-v2">
                            <div class="kt-user-card-v2__pic d-flex">
                                <ngx-avatar *ngIf="!record.picture" size="43" [round]="true" name="{{commonHelper.getFirstAndLastName(record.name)}}" bgColor="#7a7a7a"></ngx-avatar>
                                <ngx-avatar *ngIf="record.picture" size="43" [round]="true" src="{{record.picture}}"></ngx-avatar>
                            </div>
                            {{record.name}}
                        </div>
                    </td>
                    <td style="width: 100px">
                        {{record.leaveTypeName | localize}}
                    </td>
                    <td style="width: 150px">
                        {{record.startingDT | date: pipeBaseDateTimeFormat:'UTC'}}
                    </td>
                    <td style="width: 150px">
                        {{record.endingDT | date: pipeBaseDateTimeFormat:'UTC'}}
                    </td>
                    <td *ngIf="!fromDashboard" style="width: 200px">
                        {{record.leaveReason}}
                    </td>
                    <td *ngIf="!fromDashboard" style="width: 40px">
                        <i *ngIf="record.haveConflits" style="font-size: 1.8em; color: #c82333" class="flaticon-danger"></i>
                    </td>
                    <td *ngIf="!fromDashboard && (!isApprovedSelected || !isApprovedSelected && !isRejectedSelected && !isCancelledSelected)" style="width: 80px;">
                        <button (click)="approve(record)" class="btn btn-primary btn-success">{{'Approve' | localize}}</button>
                    </td>
                    <td *ngIf="!fromDashboard && (!isRejectedSelected && !isCancelledSelected || !isApprovedSelected && !isRejectedSelected && !isCancelledSelected)" style="width: 80px;">
                        <button (click)="reject(record)" class="btn btn-primary btn-danger">{{'Reject' | localize}}</button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
            {{'NoData' | localize}}
        </div>
    </div>
    <!--<Primeng-TurboTable-End>-->
</div>

<rejectionReasonModal #rejectionReasonModal></rejectionReasonModal>