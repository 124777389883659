import { Component, EventEmitter, Injector, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'rejectionReasonModal',
    templateUrl: './rejection-reason-modal.component.html'
})
export class RejectionReasonModalComponent extends AppComponentBase {

    @ViewChild('rejectionReasonModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    rejectionReason: string;
    saving = false;

    constructor(
        injector: Injector
    ) {
        super(injector);
    }

    show() {
        this.modal.show();
    }

    save() {
        this.saving = true;
        this.modalSave.emit(this.rejectionReason);
        this.resetRejectionReason();
        this.saving = false;
        this.hideModal();
    }

    close() {
        this.resetRejectionReason();
        this.hideModal();
    }

    private hideModal() {
        this.modal.hide();
    }

    private resetRejectionReason() {
        this.rejectionReason = null
    }
}
