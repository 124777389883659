<div class="kt-portlet kt-portlet--height-fluid sales-summary-chart">
        <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                                {{"mycalender" | localize}}
                        </h3>
                        <a href="app/main/my-calendar">
                                &nbsp;<i class="fas fa-external-link-alt"></i>
                        </a>
                </div>
                <h6 class="d-flex align-items-center">
                        {{ viewDate | date:formatDate }}
                </h6>
        </div>
        <div class="kt-portlet__body" [perfectScrollbar]>
                <div id="month-calendar-container">
                        <app-month-calendar-base #mc></app-month-calendar-base>
                </div>
        </div>
</div>