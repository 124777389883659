import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AvatarModule } from "ngx-avatar";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TeamCalendarBaseComponent } from "./team-calendar-base.component";

@NgModule({
    imports: [
        CommonModule,
        PopoverModule.forRoot(),
        AvatarModule.forRoot()
    ],
    declarations: [
        TeamCalendarBaseComponent
    ],
    exports: [
        TeamCalendarBaseComponent
    ]
})
export class TeamCalendarBaseModule { }
