<div appBsModal #addWidgetModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true"
    [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #addWidgetModalForm="ngForm" (ngSubmit)="save()">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span>{{"AddWidget" | localize}}</span>
                    </h5>
                    <button type="button" class="close" [attr.aria-label]="l('Close')" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="form-group" *ngIf="widgets && widgets.length > 0">
                        <select autoFocus size="1" class="form-control" name="selectedWidget"
                            [(ngModel)]="selectedWidgetId">
                            <option *ngFor="let widget of widgets" [value]="widget.id">{{widget.name}}</option>
                        </select>
                    </div>
                    <div class="form-group" *ngIf="!widgets || widgets.length == 0">{{"NoWidgetAvailableMessage" |localize}}
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="close()"
                        [disabled]="saving">{{"Cancel" | localize}}</button>
                    <button type="submit" class="btn btn-primary" [disabled]="saving"><i class="fa fa-save"></i>
                        <span>{{"Save" | localize}}</span></button>
                </div>
            </form>
        </div>
    </div>
</div>