import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppBsModalModule } from "@shared/common/appBsModal/app-bs-modal.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { AvatarModule } from "ngx-avatar";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { LeaveRequestsBaseComponent } from "./leave-requests-base.component";
import { RejectionReasonModalComponent } from "./rejection-reason-modal.component";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";

@NgModule({
    imports: [
        CommonModule,
        PopoverModule.forRoot(),
        AvatarModule.forRoot(),
        TableModule,
        PaginatorModule,
        UtilsModule,
        AppBsModalModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    declarations: [
        LeaveRequestsBaseComponent,
        RejectionReasonModalComponent
    ],
    exports: [
        LeaveRequestsBaseComponent
    ]
})
export class LeaveRequestsBaseModule { }
