import { IAjaxResponse, TokenService } from 'abp-ng2-module';
import { Component, Injector, ViewChild, Output, EventEmitter } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfileServiceProxy, UpdateProfilePictureInput, UserProfileDetailDto, CurrentUserProfileEditDto, GetProfilePictureOutput } from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { CommonHelper } from '@shared/helpers/CommonHelper';

@Component({
    selector: 'addProfileDetailModal',
    templateUrl: './add-profile-detail-modal.component.html'
})
export class AddProfileDetailModalComponent extends AppComponentBase {

    @ViewChild('addProfileDetailModal', { static: true }) modal: ModalDirective;

    public active = false;
    public uploader: FileUploader;
    public temporaryPictureUrl: string;
    public saving = false;
    //croppedImage = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    public maxProfilPictureBytesUserFriendlyValue = 5;
    private temporaryPictureFileName: string;
    private _uploaderOptions: FileUploaderOptions = {};
    files: any;
    imageChangedEvent: any = '';
    user: UserProfileDetailDto = new UserProfileDetailDto();
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    profilePicture: any;

    commonHelper = CommonHelper;

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _tokenService: TokenService
    ) {
        super(injector);
    }

    initializeModal(): void {
        this.user.currentUserProfileEditDto = new CurrentUserProfileEditDto();
        this.user.getProfilePictureOutput = new GetProfilePictureOutput();
        this.active = true;
        this.temporaryPictureUrl = '';
        this.temporaryPictureFileName = '';
        this.initFileUploader();
    }

    show(): void {
        this.initializeModal();
        this._profileService.getUserProfileDetails(this.appSession.userId).subscribe(result => {
            this.user = result;
            if (this.user.getProfilePictureOutput == undefined || this.user.getProfilePictureOutput.profilePicture == "") {
                if (this.user.getProfilePictureOutput == undefined) {
                    this.user.getProfilePictureOutput = new GetProfilePictureOutput();
                }
                this.user.getProfilePictureOutput.profilePicture = '';
            }
            this.modal.show();
        });
    }

    close(): void {
        this.user = new UserProfileDetailDto();
        this.active = false;
        this.imageChangedEvent = '';
        this.uploader.clearQueue();
        this.modal.hide();
    }

    fileChangeEvent(event: any): void {
        if (event.target.files[0].size > 5242880) { //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxProfilPictureBytesUserFriendlyValue));
            return;
        }
        this.files = event.target.files[0];
        this.imageChangedEvent = event;
    }

    imageCroppedFile(event: ImageCroppedEvent) {
        this.uploader.clearQueue();
        this.uploader.addToQueue([<File>base64ToFile(event.base64)]);
        this.user.getProfilePictureOutput.profilePicture = event.base64;
    }

    //imageCropped(event: ImageCroppedEvent) {
    //    this.croppedImage = event.base64;
    //}

    initFileUploader(): void {
        this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePicture' });
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'ProfilePicture');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.updateProfilePicture(resp.result.fileToken);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.setOptions(this._uploaderOptions);
    }

    updateProfilePicture(fileToken: string): void {
        this.profilePicture = new UpdateProfilePictureInput();
        this.profilePicture.fileToken = fileToken;
        this.profilePicture.x = 0;
        this.profilePicture.y = 0;
        this.profilePicture.width = 0;
        this.profilePicture.height = 0;

        this.saving = true;
        //this._profileService.updateProfilePicture(input)
        //    .pipe(finalize(() => { this.saving = false; }))
        //    .subscribe(() => {
        //        abp.event.trigger('profilePictureChanged');
        //        this.close();
        //    });
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    save(): void {
        // this.uploader.uploadAll();
        setTimeout(() => {
            this.saving = true;
            if (this.profilePicture != undefined) {
                this.user.updateProfilePictureInput = this.profilePicture;
            }
            this._profileService.addOrUpdateProfileDetails(this.user)
                .pipe(finalize(() => { this.saving = false; }))
                .subscribe(() => {
                    this.notify.info(this.l('SavedSuccessfully'));
                    this.appSession.user.name = this.user.currentUserProfileEditDto.name;
                    this.appSession.user.surname = this.user.currentUserProfileEditDto.surname;
                    this.appSession.user.emailAddress = this.user.currentUserProfileEditDto.emailAddress;
                    if (this.user.getProfilePictureOutput && this.user.getProfilePictureOutput.profilePicture) {
                        abp.event.trigger('profilePictureChanged');
                        this.profilePicture = undefined;
                    }
                    this.close();
                    this.modalSave.emit();
                });
        }, 1000);
    }

    get getNameAndSurname() {
        return `${this.user.currentUserProfileEditDto.name} ${this.user.currentUserProfileEditDto.surname}`;
    }
}
