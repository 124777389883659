import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AppBsModalModule } from "@shared/common/appBsModal/app-bs-modal.module";
import { UtilsModule } from "@shared/utils/utils.module";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { AngularCalendarYearViewModule } from "angular-calendar-year-view";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AvatarModule } from "ngx-avatar";
import { PopoverModule } from "ngx-bootstrap/popover";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { MonthCalendarBaseComponent } from "./month-calendar-base.component";

@NgModule({
    imports: [
        CommonModule,
        PopoverModule.forRoot(),
        AvatarModule.forRoot(),
        TableModule,
        PaginatorModule,
        UtilsModule,
        AppBsModalModule,
        AngularCalendarYearViewModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    declarations: [
        MonthCalendarBaseComponent
    ],
    exports: [
        MonthCalendarBaseComponent
    ]
})
export class MonthCalendarBaseModule { }
